import React from "react";
import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { toast } from "react-toastify";
import SectionsModel from "../../../models/SectionModel";
import ChangeSegmentButtonsContainer from "../../common/change-segment-button";
import colors from "../../../constants/color-constants";

export default function TowerSectionLeftMenu({
  nSegments, 
  sections, 
  setSections, 
  inputValues, 
  setInputValues,
  sectionSelectedInMenu, 
  setSectionSelectedInMenu, 
  handleInputChange,
  setSectionHeights,
  trussesHeights,
}) {
  function handlePreviousSection() {
    if (sectionSelectedInMenu === "") return;
    if (Number(sectionSelectedInMenu) === 1) {
      setSectionSelectedInMenu("");
      return;
    }
    setSectionSelectedInMenu(Number(sectionSelectedInMenu) - 1);
  }

  function handleNextSection() {
    if (sectionSelectedInMenu === "") {
      setSectionSelectedInMenu(1);
      return;
    }
    if (Number(sectionSelectedInMenu) === Number(sections.length)) return;
    setSectionSelectedInMenu(Number(sectionSelectedInMenu) + 1);
  }

  function addSection() {
    if (sections.length >= 3) return toast.error("Não é possível adicionar mais de 3 seções.");

    const newSectionNumber = sections?.length + 1;
    newSectionNumber && setSections((prevSections) => [...prevSections, `Seção ${newSectionNumber}`]);

    const initialSegmentValue = inputValues[sections.length - 1].finalSegment !== ""
      ? inputValues[sections.length - 1].finalSegment + 1 : "";
    const topWidthValue = inputValues[sections.length - 1].baseWidth;

    const sectionsModel = new SectionsModel([
      ...inputValues,
      {
        sectionNumber: newSectionNumber,
        initialSegment: initialSegmentValue,
        finalSegment: "",
        topWidth: topWidthValue,
        baseWidth: "",
      },
    ]);

    setSectionHeights(sectionsModel.updateSectionHeights(trussesHeights));
    setInputValues(sectionsModel.sections);
    setSectionSelectedInMenu(newSectionNumber);
  }

  function removeSection() {
    if (sections.length <= 1) {
      return toast.error("Deve haver pelo menos uma seção.");
    }
    const newSections = sections.slice(0, -1);
    setSections(newSections);

    const sectionsModel = new SectionsModel([
      ...inputValues.filter((_, index) => index < newSections.length)
    ]);

    setSectionHeights(sectionsModel.updateSectionHeights(trussesHeights));
    setInputValues(sectionsModel.sections);
    setSectionSelectedInMenu(newSections.length);
  }

  return(
    <>
      <InputMenu>
        <h2>Seção Atual</h2>
        <select
          className="highlightColor"
          value={sectionSelectedInMenu}
          onChange={(e) => setSectionSelectedInMenu(e.target.value)}
        >
          <option value="">Selecione uma seção</option>
          {[...Array(sections.length).keys()].map((i) => (
            <option value={i + 1} key={`section-${i + 1}`}>
              {sections[i]}
            </option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Trecho Inicial da Seção</h2>
        <select
          disabled={sectionSelectedInMenu === ""}
          value={inputValues[sectionSelectedInMenu - 1]?.initialSegment || ""}
          onChange={(e) => handleInputChange("initialSegment", Number(e.target.value))}
        >
          <option value="">Selecione o trecho inicial da seção</option>
          {[...Array(nSegments).keys()].map((i) => (
            <option value={i + 1} key={`section-${i + 1}`}>
              Trecho {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Trecho Final da Seção</h2>
        <select 
          disabled={sectionSelectedInMenu===""}
          value={inputValues[sectionSelectedInMenu - 1]?.finalSegment || ""}
          onChange={(e) => handleInputChange("finalSegment", Number(e.target.value))}
        >
          <option value="">Selecione o trecho final da seção</option>
          {[...Array(nSegments).keys()].map((i) => (
            <option value={i + 1} key={`section-${i + 1}`}>
              Trecho {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>{"Largura no Topo da Seção (mm)"}</h2>
        <CustomInput
          disabled={sectionSelectedInMenu===""}
          placeholder="Informe a largura no topo da seção"
          value={inputValues[sectionSelectedInMenu - 1]?.topWidth || ""} 
          onChange={(newValue) => handleInputChange("topWidth", newValue)}
          isNumber={true}
          unit={"mm"}
        />
      </InputMenu>

      <InputMenu>
        <h2>{"Largura na Base da Seção (mm)"}</h2>
        <CustomInput
          disabled={sectionSelectedInMenu===""}
          placeholder="Informe a largura na base da seção"
          value={inputValues[sectionSelectedInMenu - 1]?.baseWidth || ""} 
          onChange={(newValue) => handleInputChange("baseWidth", newValue)}
          isNumber={true}
          unit={"mm"}
        />
      </InputMenu>

      <AddRemoveSectionContainer>
        <AddSectionButton onClick={addSection}>
          <p>{"Adicionar Seção Inclinada / Reta"}</p>
          <div> + </div>
        </AddSectionButton>

        <div onClick={removeSection}>
          <ion-icon name="trash"></ion-icon>
        </div>
      </AddRemoveSectionContainer>

      <ChangeSegmentButtonsContainer
        nSegments={sections.length}
        segmentSelectedInMenu={sectionSelectedInMenu}
        setSegmentSelectedInMenu={setSectionSelectedInMenu}
        previousButtonText={"Seção anterior"}
        nextButtonText={"Próxima seção"}
      />
    </>
  );  
}

const AddRemoveSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  gap: 8px;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: ${colors.kcButtonLightGray};
    cursor: pointer;
  }

  >div:hover {
    background-color: ${colors.kcRed};
    color: ${colors.kcWhiteColor};

    ion-icon {
        color: ${colors.kcWhiteColor};
    }
  }

  ion-icon {
    color: ${colors.kcBlackColor};
    font-size: 25px;
  }
`;

const AddSectionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  background-color: ${colors.kcButtonLightGray};
  border-radius: 6px;
  cursor: pointer;
    
  :hover {
    background-color: ${colors.kcButtonGray};
  }

  p {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }

  div {
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    border-radius: 30px;
    background-color: ${colors.kcWhiteColor};
    font-size: 18px;
  }
`;
