import styled from "styled-components";
import colors from "../../../constants/color-constants";

export default function TowerAntennasAEVTable({
  inputValues,
  totals,
  conditionsColors,
}) {
  return (
    <Container>
      <AEVItem className={"title"}>
        {"Resumo AEV"}
      </AEVItem>
      <AEVItem color={conditionsColors.existing}>
        {`AEV existente (com C.A.): ${totals.existing.toFixed(3) || "0.000"}m²`}
      </AEVItem>
      <AEVItem color={conditionsColors.remove}>
        {`AEV à retirar (com C.A.): ${totals.remove.toFixed(3) || "0.000"}m²`}
      </AEVItem>
      <AEVItem color={conditionsColors.install}>
        {`AEV à instalar (com C.A.): ${totals.install.toFixed(3) || "0.000"}m²`}
      </AEVItem>
      <AEVItem color={conditionsColors.reserve}>
        {`AEV Reserva (com C.A.): ${totals.reserve.toFixed(3) || "0.000"}m²`}
      </AEVItem>
      <AEVItem>
        {`AEV Total (com C.A.): ${totals.all.toFixed(3) || "0.000"}m²`}
      </AEVItem>
      <AEVItem color={colors.kcDarkGray}>
        {`Esteira de Cabos: ${inputValues?.cableTrayLength || 0}mm`}
      </AEVItem>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: min-content;
    padding: 4px;
    gap: 4px;
    border-radius: 6px;
    background-color: #E2E2E2;
    font-family: 'Roboto', sans-serif;
    
    div.title {
        background-color: #464646;
        color: ${colors.kcOrange};
        font-weight: 600;
    }
`;

const AEVItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    background-color: ${colors.kcWhiteColor};
    color: ${(props) => props.color || colors.kcBlackColor};
`;
