import React from "react";
import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { toast } from "react-toastify";
import PlatformsModel from "../../../models/PlatformModel";
import ChangeSegmentButtonsContainer from "../../common/change-segment-button";
import colors from "../../../constants/color-constants";

export default function TowerPlatformLeftMenu({
  platforms,
  setPlatforms,
  inputValues, 
  setInputValues,
  platformSelectedInMenu, 
  setPlatformSelectedInMenu, 
  platformsTypes, 
  handleInputChange, 
  addPlatform,
}) {
  function handlePreviousPlatform() {
    if (platformSelectedInMenu === "") return;
    if (Number(platformSelectedInMenu) === 1) {
      setPlatformSelectedInMenu("");
      return;
    }
    setPlatformSelectedInMenu(Number(platformSelectedInMenu) - 1);
  }

  function handleNextPlatform() {
    if (platformSelectedInMenu === "") {
      setPlatformSelectedInMenu(1);
      return;
    }
    if (Number(platformSelectedInMenu) === Number(platforms.length)) return;
    setPlatformSelectedInMenu(Number(platformSelectedInMenu) + 1);
  }

  function removePlatform() {
    if (platforms.length <= 1) {
      return toast.error("Deve haver pelo menos uma plataforma.");
    }
    const newPlatforms = platforms.slice(0, -1);
    setPlatforms(newPlatforms);

    const platformsModel = new PlatformsModel([
      ...inputValues.filter((_, index) => index < newPlatforms.length)
    ]);

    setInputValues(platformsModel.platforms);
    setPlatformSelectedInMenu(newPlatforms.length);
  }

  return(
    <>
      <InputMenu>
        <h2>Nº da Plataforma</h2>
        <select
          className="highlightColor"
          value={platformSelectedInMenu}
          onChange={(e) => setPlatformSelectedInMenu(e.target.value)}
        >
          <option value="">Selecione uma plataforma</option>
          {[...Array(platforms.length).keys()].map((i) => (
            <option value={i + 1} key={`platform-${i + 1}`}>
              Plataforma {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Tipo da Plataforma</h2>
        <select 
          disabled={platformSelectedInMenu===""}
          placeholder="Selecione o tipo da plataforma"
          value={platformSelectedInMenu==="" 
            ? "" : inputValues[platformSelectedInMenu - 1]?.platformType || ""} 
          onChange={(e) => handleInputChange("platformType", e.target.value)}
        >
          <option value="">Selecione o tipo da plataforma</option>
          {platformsTypes.map(option => (
            <option value={option} key={option}>{option}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>{"Elevação da Plataforma (m)"}</h2>
        <CustomInput
          disabled={platformSelectedInMenu===""}
          placeholder="Informe a elevação da plataforma"
          value={inputValues[platformSelectedInMenu - 1]?.platformElevation}
          onChange={newValue => handleInputChange("platformElevation", newValue)}
          isNumber={true}
          unit={"m"}
        />
      </InputMenu>

      <AddRemovePlatformContainer>
        <AddPlatformButton onClick={addPlatform}>
          <p>{"Adicionar plataforma"}</p>
          <div> + </div>
        </AddPlatformButton>

        <div onClick={removePlatform}>
          <ion-icon name="trash"></ion-icon>
        </div>
      </AddRemovePlatformContainer>

      <ChangeSegmentButtonsContainer
        nSegments={platforms.length}
        segmentSelectedInMenu={platformSelectedInMenu}
        setSegmentSelectedInMenu={setPlatformSelectedInMenu}
        previousButtonText={"Plataforma anterior"}
        nextButtonText={"Próxima plataforma"}
      />
    </>
  );  
}

const AddRemovePlatformContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  gap: 8px;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: ${colors.kcButtonLightGray};
    cursor: pointer;
  }

  >div:hover {
    background-color: ${colors.kcRed};
    color: ${colors.kcWhiteColor};

    ion-icon {
        color: ${colors.kcWhiteColor};
    }
  }

  ion-icon {
    color: ${colors.kcBlackColor};
    font-size: 25px;
  }
`;

const AddPlatformButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  background-color: ${colors.kcButtonLightGray};
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background-color: ${colors.kcButtonGray};
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  div {
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    border-radius: 30px;
    background-color: ${colors.kcWhiteColor};
    font-size: 18px;
  }
`;
