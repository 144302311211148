import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";
import { trussesInfo } from "../constants/tower-trusses-data";

class SuperiorHorizontalDataModel {
  constructor(
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    reinforcementDimensions = "",
    reinforcementDiameter = "",
    reinforcementThickness = "",
    reinforcementFlange = "",
    reinforcementWeb = ""
  ) {
    this.profileType = profileType;
    this.profileSteel = profileSteel;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.boltsSteel = boltsSteel;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
    this.reinforcementDimensions = reinforcementDimensions;
    this.reinforcementDiameter = reinforcementDiameter;
    this.reinforcementThickness = reinforcementThickness;
    this.reinforcementFlange = reinforcementFlange;
    this.reinforcementWeb = reinforcementWeb;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      this.reinforcementDimensions = "";
      this.reinforcementDiameter = "";
      this.reinforcementThickness = "";
      this.reinforcementFlange = "";
      this.reinforcementWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.reinforcementDimensions = "";
        this.reinforcementDiameter = "";
        this.reinforcementThickness = "";
        this.reinforcementFlange = "";
        this.reinforcementWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }

      if ((field === "reinforcementDiameter" || field === "reinforcementThickness") && value === "Não Há Reforço") {
        this.reinforcementDiameter = "Não Há Reforço";
        this.reinforcementThickness = "Não Há Reforço";
        return;
      }

      if (field === "reinforcementDiameter") {
        this[field] = value;
        this.reinforcementThickness = "";
        return;
      }
    }

    if (this.profileType === "U - Perfil U") {
      if (field === "reinforcementThickness") {
        if (value === "Não Há Reforço") {
          this.reinforcementFlange = "Não Há Reforço";
          this.reinforcementWeb = "Não Há Reforço";
          this.reinforcementThickness = "Não Há Reforço";
        } else {
          if (this.reinforcementThickness === "Não Há Reforço") {
            this.reinforcementFlange = "";
            this.reinforcementWeb = "";
            this.reinforcementThickness = value;
          } else {
            this.reinforcementThickness = value;
          }
        }
        return;
      }
    }

    this[field] = value;
  }
}

class HorizontalModel {
  constructor(
    segmentId = "",
    trussType = "",
    mountDivisions = "",
    bracingType = "",
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    reinforcementDimensions = "",
    reinforcementDiameter = "",
    reinforcementThickness = "",
    reinforcementFlange = "",
    reinforcementWeb = "",
    isSuperiorHorizontalDifferent = "",
    superiorHorizontalData = {}
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;
    this.mountDivisions = mountDivisions;
    this.bracingType = bracingType;
    this.isSuperiorHorizontalDifferent = trussType === "Treliça K" ? "Sim" : isSuperiorHorizontalDifferent;

    if (this.hasMainHorizontal(this.trussType, this.mountDivisions, this.bracingType)) {
      this.profileType = profileType;
      this.profileSteel = profileSteel;
      this.profileDimensions = profileDimensions;
      this.profileDiameter = profileDiameter;
      this.profileThickness = profileThickness;
      this.profileFlange = profileFlange;
      this.profileWeb = profileWeb;
      this.boltsSteel = boltsSteel;
      this.numConnectionBolts = numConnectionBolts;
      this.diameterConnectionBolts = diameterConnectionBolts;
      this.reinforcementDimensions = reinforcementDimensions;
      this.reinforcementDiameter = reinforcementDiameter;
      this.reinforcementThickness = reinforcementThickness;
      this.reinforcementFlange = reinforcementFlange;
      this.reinforcementWeb = reinforcementWeb;
    }

    this.superiorHorizontalData = isSuperiorHorizontalDifferent !== "NA"
      ? new SuperiorHorizontalDataModel(
        superiorHorizontalData.profileType,
        superiorHorizontalData.profileSteel,
        superiorHorizontalData.profileDimensions,
        superiorHorizontalData.profileDiameter,
        superiorHorizontalData.profileThickness,
        superiorHorizontalData.profileFlange,
        superiorHorizontalData.profileWeb,
        superiorHorizontalData.boltsSteel,
        superiorHorizontalData.numConnectionBolts,
        superiorHorizontalData.diameterConnectionBolts,
        superiorHorizontalData.reinforcementDimensions,
        superiorHorizontalData.reinforcementDiameter,
        superiorHorizontalData.reinforcementThickness,
        superiorHorizontalData.reinforcementFlange,
        superiorHorizontalData.reinforcementWeb,
      ) : {};
  }

  hasMainHorizontal(trussType, mountDivisions, bracingType) {
    if (trussType !== "Treliça X") return false;

    const trussInfo = trussesInfo["Treliça X"][mountDivisions].find(truss => truss.bracingType === bracingType);
    return trussInfo.hasHorizontal;
  }

  updateHorizontalField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "superiorHorizontalData" && value === "empty") {
      this.isSuperiorHorizontalDifferent === "Sim" || this.isSuperiorHorizontalDifferent === "Não"
        ? this.superiorHorizontalData = new SuperiorHorizontalDataModel()
        : this.superiorHorizontalData = {};
      return;
    }

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      this.reinforcementDimensions = "";
      this.reinforcementDiameter = "";
      this.reinforcementThickness = "";
      this.reinforcementFlange = "";
      this.reinforcementWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.reinforcementDimensions = "";
        this.reinforcementDiameter = "";
        this.reinforcementThickness = "";
        this.reinforcementFlange = "";
        this.reinforcementWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }

      if ((field === "reinforcementDiameter" || field === "reinforcementThickness") && value === "Não Há Reforço") {
        this.reinforcementDiameter = "Não Há Reforço";
        this.reinforcementThickness = "Não Há Reforço";
        return;
      }

      if (field === "reinforcementDiameter") {
        this[field] = value;
        this.reinforcementThickness = "";
        return;
      }
    }

    if (this.profileType === "U - Perfil U") {
      if (field === "reinforcementThickness") {
        if (value === "Não Há Reforço") {
          this.reinforcementFlange = "Não Há Reforço";
          this.reinforcementWeb = "Não Há Reforço";
          this.reinforcementThickness = "Não Há Reforço";
        } else {
          if (this.reinforcementThickness === "Não Há Reforço") {
            this.reinforcementFlange = "";
            this.reinforcementWeb = "";
            this.reinforcementThickness = value;
          } else {
            this.reinforcementThickness = value;
          }
        }
        return;
      }
    }

    if (field === "isSuperiorHorizontalDifferent" && value !== this.isSuperiorHorizontalDifferent) {
      this[field] = value;
      if (value === "NA") {
        this.superiorHorizontalData = {};
      } else {
        this.superiorHorizontalData = new SuperiorHorizontalDataModel(this.superiorHorizontalData);
      }
      return;
    }

    this[field] = value;
  }

  updateSuperiorHorizontalField(field, value) {
    this.superiorHorizontalData.updateField(field, value);
  }
}

class HorizontalsModel {
  constructor(horizontals = []) {
    this.horizontals = horizontals.map(horizontal => new HorizontalModel(
      horizontal.segmentId,
      horizontal.trussType,
      horizontal.mountDivisions,
      horizontal.bracingType,
      horizontal.profileType,
      horizontal.profileSteel,
      horizontal.profileDimensions,
      horizontal.profileDiameter,
      horizontal.profileThickness,
      horizontal.profileFlange,
      horizontal.profileWeb,
      horizontal.boltsSteel,
      horizontal.numConnectionBolts,
      horizontal.diameterConnectionBolts,
      horizontal.reinforcementDimensions,
      horizontal.reinforcementDiameter,
      horizontal.reinforcementThickness,
      horizontal.reinforcementFlange,
      horizontal.reinforcementWeb,
      horizontal.isSuperiorHorizontalDifferent,
      horizontal.superiorHorizontalData
    ));
  }

  updateHorizontal(segmentId, field, value) {
    const horizontal = this.horizontals.find(horizontal => horizontal.segmentId === segmentId);
    if (horizontal) {
      horizontal.updateHorizontalField(field, value);
    }

    if (field === "isSuperiorHorizontalDifferent") {
      this.setSuperiorHorizontalDataEqual();
    }
  }

  updateSuperiorHorizontal(segmentId, field, value) {
    const horizontal = this.horizontals.find(horizontal => horizontal.segmentId === segmentId);
    if (horizontal && (horizontal.isSuperiorHorizontalDifferent === "Sim" || horizontal.isSuperiorHorizontalDifferent === "")) {
      horizontal.updateSuperiorHorizontalField(field, value);
    }
  }

  checkForEmptyTrussesValues(horizontal, hasSuperiorHorizontal) {
    if (horizontal.isSuperiorHorizontalDifferent !== "") return;

    if (hasSuperiorHorizontal === "Não") {
      horizontal.updateHorizontalField("isSuperiorHorizontalDifferent", "NA");
      return;
    }

    if (horizontal.isSuperiorHorizontalDifferent === ""
      && (horizontal.trussType === "Treliça K" || horizontal.trussType === "Treliça V"
        || (horizontal.trussType === "Treliça H" && horizontal.bracingType === "B"))
      && !(horizontal.trussType === "Treliça X"
        && (Number(horizontal.mountDivisions) === 2 || Number(horizontal.mountDivisions) === 2)
        && (horizontal.bracingType === "A" || horizontal.bracingType === "B"))
    ) {
      horizontal.updateHorizontalField("isSuperiorHorizontalDifferent", "Sim");
    }
  }

  setSuperiorHorizontalDataEqual() {
    for (const horizontal of this.horizontals) {
      if (horizontal.isSuperiorHorizontalDifferent !== "Não") continue;

      horizontal.updateSuperiorHorizontalField("profileType", horizontal.profileType);
      horizontal.updateSuperiorHorizontalField("profileSteel", horizontal.profileSteel);
      horizontal.updateSuperiorHorizontalField("profileDimensions", horizontal.profileDimensions);
      horizontal.updateSuperiorHorizontalField("profileDiameter", horizontal.profileDiameter);
      horizontal.updateSuperiorHorizontalField("profileThickness", horizontal.profileThickness);
      horizontal.updateSuperiorHorizontalField("profileFlange", horizontal.profileFlange);
      horizontal.updateSuperiorHorizontalField("profileWeb", horizontal.profileWeb);
      horizontal.updateSuperiorHorizontalField("boltsSteel", horizontal.boltsSteel);
      horizontal.updateSuperiorHorizontalField("numConnectionBolts", horizontal.numConnectionBolts);
      horizontal.updateSuperiorHorizontalField("diameterConnectionBolts", horizontal.diameterConnectionBolts);
      horizontal.updateSuperiorHorizontalField("reinforcementDimensions", horizontal.reinforcementDimensions);
      horizontal.updateSuperiorHorizontalField("reinforcementDiameter", horizontal.reinforcementDiameter);
      horizontal.updateSuperiorHorizontalField("reinforcementThickness", horizontal.reinforcementThickness);
      horizontal.updateSuperiorHorizontalField("reinforcementFlange", horizontal.reinforcementFlange);
      horizontal.updateSuperiorHorizontalField("reinforcementWeb", horizontal.reinforcementWeb);
    }
  }

  validate(trussesWithHorizontals) {
    const errors = [];

    this.horizontals.forEach((segment, index) => {
      if (segment.hasMainHorizontal(segment.bracingType, segment.mountDivisions, segment.bracingType)) {
        this._validateSegment(segment, errors, index);
      }

      if (trussesWithHorizontals[index] === "Sim"
        && (segment.isSuperiorHorizontalDifferent === "Sim" || segment.isSuperiorHorizontalDifferent === "")) {
        this._validateSuperiorHorizontal(segment, errors, index);
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações das horizontais. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  _validateSegment(segment, errors, index) {
    if (!segment.profileType) errors.push(`Tipo de Perfil no trecho ${index + 1}`);
    if (segment.profileSteel === "") errors.push(`Aço do Perfil no trecho ${index + 1}`);

    const hasProfileDimensions = segment.profileDimensions || segment.profileDiameter || segment.profileThickness
      || segment.profileFlange || segment.profileWeb;
    if (!hasProfileDimensions) {
      errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
    } else {
      if (segment.profileType === "T - Tubo Vazado" && (!segment.profileDiameter || !segment.profileThickness))
        errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
      if (segment.profileType === "U - Perfil U" &&
        (!segment.profileFlange || !segment.profileWeb || !segment.profileThickness))
        errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
    }

    if (!segment.boltsSteel) errors.push(`Aço dos Parafusos no trecho ${index + 1}`);
    if (!segment.numConnectionBolts) errors.push(`Quantidade de parafusos de conexão no trecho ${index + 1}`);
    if (!segment.diameterConnectionBolts) errors.push(`Diâmetro de parafusos de conexão no trecho ${index + 1}`);

    const hasReinforcementDimensions = segment.reinforcementDimensions || segment.reinforcementDiameter
      || segment.reinforcementThickness || segment.reinforcementFlange || segment.reinforcementWeb;
    if (!hasReinforcementDimensions) {
      errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
    }else {
      if (segment.profileType === "T - Tubo Vazado" && (!segment.reinforcementDiameter || !segment.reinforcementThickness))
        errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
      if (segment.profileType === "U - Perfil U" &&
        (!segment.reinforcementFlange || !segment.reinforcementWeb || !segment.reinforcementThickness))
        errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
    }

    if (segment.isSuperiorHorizontalDifferent === "")
      errors.push(`Se a horizontal superior é diferente no trecho ${index + 1}`);
    if (segment.isSuperiorHorizontalDifferent === "Sim" || segment.isSuperiorHorizontalDifferent === "Não") {
      this._validateSuperiorHorizontal(segment, errors, index);
    }
  }

  _validateSuperiorHorizontal(segment, errors, index) {
    if (!segment.superiorHorizontalData.profileType) {
      errors.push(`Tipo de Perfil da horizontal superior no trecho ${index + 1}`);
    }

    const hasDimensions = segment.superiorHorizontalData.profileDimensions
      || segment.superiorHorizontalData.profileDiameter || segment.superiorHorizontalData.profileThickness
      || segment.superiorHorizontalData.profileFlange || segment.superiorHorizontalData.profileWeb;
    if (!hasDimensions) errors.push(`Dimensões do Perfil da horizontal superior no trecho ${index + 1}`);

    if (segment.superiorHorizontalData.profileSteel === "")
      errors.push(`Aço do Perfil da horizontal superior no trecho ${index + 1}`);

    if (!segment.superiorHorizontalData.numConnectionBolts)
      errors.push(`Quantidade de parafusos de conexão da horizontal superior no trecho ${index + 1}`);

    if (!segment.superiorHorizontalData.diameterConnectionBolts)
      errors.push(`Diâmetro de parafusos de conexão da horizontal superior no trecho ${index + 1}`);

    if (!segment.superiorHorizontalData.boltsSteel)
      errors.push(`Aço dos Parafusos da horizontal superior no trecho ${index + 1}`);

    const hasReinforcementDimensions = segment.superiorHorizontalData.reinforcementDimensions
      || segment.superiorHorizontalData.reinforcementDiameter || segment.superiorHorizontalData.reinforcementThickness
      || segment.superiorHorizontalData.reinforcementFlange || segment.superiorHorizontalData.reinforcementWeb;
    if (!hasReinforcementDimensions) {
      errors.push(`Dimensões do Perfil reforço da horizontal superior no trecho ${index + 1}`);
    } else {
      if (segment.superiorHorizontalData.profileType === "T - Tubo Vazado" &&
        (!segment.superiorHorizontalData.reinforcementDiameter || !segment.superiorHorizontalData.reinforcementThickness))
        errors.push(`Dimensões do Perfil reforço da horizontal superior no trecho ${index + 1}`);
      if (segment.superiorHorizontalData.profileType === "U - Perfil U"
        && (!segment.superiorHorizontalData.reinforcementFlange || !segment.superiorHorizontalData.reinforcementWeb
          || !segment.superiorHorizontalData.reinforcementThickness))
        errors.push(`Dimensões do Perfil reforço da horizontal superior no trecho ${index + 1}`);
    }
  }

  toJSON() {
    return JSON.stringify(this.horizontals);
  }
}

export default HorizontalsModel;
