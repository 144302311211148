import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerHorizontalLeftMenu from "./tower_horizontalLeftMenu";
import TowerHorizontalRightContainer from "./tower_horizontalRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerHorizontals } from "../../../hooks/api/tower-api/useTowerHorizontals";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HorizontalsModel from "../../../models/HorizontalModel";
import { trussesInfo } from "../../../constants/tower-trusses-data";

export default function TowerHorizontalPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected,
    horizontalSelected, setHorizontalSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation,
    isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchHorizontals } = useTowerHorizontals("get");
  const { act: saveHorizontals } = useTowerHorizontals("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const trussesTypes = trussesSelected.map(truss => truss.trussType);
  const trussesMountDivisions = trussesSelected.map(truss => truss.mountDivisions);
  const trussesBracingTypes = trussesSelected.map(truss => truss.bracingType);
  const trussesWithSuperiorHorizontals = trussesSelected.map(truss => truss.hasSuperiorHorizontal);

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(trussesSelected.length > 0 ? 1 : "");
  const [checkedSegments, setCheckedSegments] = useState(
    Array.from({ length: trussesWithSuperiorHorizontals.length }, (_, i) => []));
  const [inputValues, setInputValues] = useState(new HorizontalsModel(horizontalSelected).horizontals);

  useEffect(() => {
    loadHorizontalsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const horizontalsModel = new HorizontalsModel(response);
        setHorizontalSelected(horizontalsModel.horizontals);
        setInputValues(horizontalsModel.horizontals);
      }
    });
  }, [towerId, userData.token]);

  const loadHorizontalsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchHorizontals(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das horizontais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [fetchHorizontals, towerId, userData.token]);

  function setInitialEmptyValues() {
    let initialData = [];
    for (let i = 0; i < trussesTypes.length; i++) {
      if (hasMainHorizontal(trussesTypes[i], trussesMountDivisions[i], trussesBracingTypes[i])) {
        initialData[i] = {
          segmentId: segmentsSelected[i].segmentId || "",
          trussType: trussesTypes[i],
          mountDivisions: trussesMountDivisions[i],
          bracingType: trussesBracingTypes[i],
          profileType: horizontalSelected[i]?.profileType || "",
          profileSteel: horizontalSelected[i]?.profileSteel || "",
          profileDimensions: horizontalSelected[i]?.profileDimensions || "",
          profileDiameter: horizontalSelected[i]?.profileDiameter || "",
          profileThickness: horizontalSelected[i]?.profileThickness || "",
          profileFlange: horizontalSelected[i]?.profileFlange || "",
          profileWeb: horizontalSelected[i]?.profileWeb || "",
          numConnectionBolts: horizontalSelected[i]?.numConnectionBolts || "",
          diameterConnectionBolts: horizontalSelected[i]?.diameterConnectionBolts || "",
          boltsSteel: horizontalSelected[i]?.boltsSteel || "",
          reinforcementDimensions: horizontalSelected[i]?.reinforcementDimensions || "",
          reinforcementDiameter: horizontalSelected[i]?.reinforcementDiameter || "",
          reinforcementThickness: horizontalSelected[i]?.reinforcementThickness || "",
          reinforcementFlange: horizontalSelected[i]?.reinforcementFlange || "",
          reinforcementWeb: horizontalSelected[i]?.reinforcementWeb || "",
          isSuperiorHorizontalDifferent: trussesWithSuperiorHorizontals[i] === "Não"
            ? "NA" : horizontalSelected[i]?.isSuperiorHorizontalDifferent || "",
        };
      } else {
        initialData[i] = {
          segmentId: segmentsSelected[i].segmentId || "",
          trussType: trussesTypes[i],
          mountDivisions: trussesMountDivisions[i],
          bracingType: trussesBracingTypes[i],
          isSuperiorHorizontalDifferent: trussesWithSuperiorHorizontals[i] === "Não"
            ? "NA" : horizontalSelected[i]?.isSuperiorHorizontalDifferent || "",
        };
      }

      initialData[i].superiorHorizontalData = trussesWithSuperiorHorizontals[i] === "Não" ? {} : {
        profileType: horizontalSelected[i]?.superiorHorizontalData?.profileType || "",
        profileSteel: horizontalSelected[i]?.superiorHorizontalData?.profileSteel || "",
        profileDimensions: horizontalSelected[i]?.superiorHorizontalData?.profileDimensions || "",
        profileDiameter: horizontalSelected[i]?.superiorHorizontalData?.profileDiameter || "",
        profileThickness: horizontalSelected[i]?.superiorHorizontalData?.profileThickness || "",
        profileFlange: horizontalSelected[i]?.superiorHorizontalData?.profileFlange || "",
        profileWeb: horizontalSelected[i]?.superiorHorizontalData?.profileWeb || "",
        numConnectionBolts: horizontalSelected[i]?.superiorHorizontalData?.numConnectionBolts || "",
        diameterConnectionBolts: horizontalSelected[i]?.superiorHorizontalData?.diameterConnectionBolts || "",
        boltsSteel: horizontalSelected[i]?.superiorHorizontalData?.boltsSteel || "",
        reinforcementDimensions: horizontalSelected[i]?.superiorHorizontalData?.reinforcementDimensions || "",
        reinforcementDiameter: horizontalSelected[i]?.superiorHorizontalData?.reinforcementDiameter || "",
        reinforcementThickness: horizontalSelected[i]?.superiorHorizontalData?.reinforcementThickness || "",
        reinforcementFlange: horizontalSelected[i]?.superiorHorizontalData?.reinforcementFlange || "",
        reinforcementWeb: horizontalSelected[i]?.superiorHorizontalData?.reinforcementWeb || "",
      };
    }

    const model = new HorizontalsModel(initialData);
    setHorizontalSelected(model.horizontals);
    setInputValues(model.horizontals);
  }

  function hasMainHorizontal(trussType, mountDivisions, bracingType) {
    if (trussType !== "Treliça X") return false;

    const trussInfo = trussesInfo["Treliça X"][mountDivisions].find(truss => truss.bracingType === bracingType);
    return trussInfo.hasHorizontal;
  }

  const fieldsToCheck = ["profileType", "profileSteel", "profileDimensions", "profileDiameter", 
    "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", 
    "diameterConnectionBolts", "reinforcementDimensions", "reinforcementDiameter", 
    "reinforcementThickness", "reinforcementFlange", "reinforcementWeb", "isSuperiorHorizontalDifferent",
    "superiorHorizontalData.profileType", "superiorHorizontalData.profileSteel", 
    "superiorHorizontalData.profileDimensions", "superiorHorizontalData.profileDiameter", 
    "superiorHorizontalData.profileFlange", "superiorHorizontalData.profileWeb", 
    "superiorHorizontalData.profileThickness", "superiorHorizontalData.boltsSteel", 
    "superiorHorizontalData.numConnectionBolts", "superiorHorizontalData.diameterConnectionBolts",
    "superiorHorizontalData.reinforcementDimensions", "superiorHorizontalData.reinforcementDiameter", 
    "superiorHorizontalData.reinforcementThickness", "superiorHorizontalData.reinforcementFlange", 
    "superiorHorizontalData.reinforcementWeb"
  ];

  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], horizontalSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value, segmentIndex) => {
    const index = segmentIndex !== undefined ? segmentIndex : segmentSelectedInMenu - 1;
    const horizontalsModel = new HorizontalsModel(inputValues);

    horizontalsModel.updateHorizontal(inputValues[index].segmentId, field, value);
    setInputValues(horizontalsModel.horizontals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const handleSuperiorHorizontalChange = useCallback((field, value) => {
    const horizontalsModel = new HorizontalsModel(inputValues);

    if (horizontalsModel.horizontals[segmentSelectedInMenu - 1].isSuperiorHorizontalDifferent === "")
      horizontalsModel.checkForEmptyTrussesValues(horizontalsModel.horizontals[segmentSelectedInMenu - 1],
        trussesWithSuperiorHorizontals[segmentSelectedInMenu - 1]);

    horizontalsModel.updateSuperiorHorizontal(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(horizontalsModel.horizontals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const setEqualSegment = (targetIndex) => {
    const currentValues = new HorizontalsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;

    const fieldsToUpdate = ["profileType", "profileDimensions", "profileDiameter", "profileThickness", 
      "profileFlange", "profileWeb", "profileSteel", "numConnectionBolts", "diameterConnectionBolts", 
      "boltsSteel", "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", 
      "reinforcementFlange", "reinforcementWeb", "isSuperiorHorizontalDifferent", "superiorHorizontalData"];

    const reinforcementFieldsToUpdate = ["profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts",
      "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", "reinforcementFlange",
      "reinforcementWeb"];
    
    if (checkedSegments[selectedSegmentIndex].includes(targetIndex)) {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetIndex);
        newChecked[targetIndex] = newChecked[targetIndex].filter(i => i !== selectedSegmentIndex);
        return newChecked;
      });

      fieldsToUpdate.forEach(field => {
        const isSuperiorHorizontalDifferent = trussesWithSuperiorHorizontals[targetIndex] === "Não"
          ? "NA"
          : (trussesTypes[targetIndex] === "Treliça K" || trussesTypes[targetIndex] === "Treliça K"
            || !(trussesTypes[targetIndex] === "Treliça X"
              && (trussesMountDivisions[targetIndex] === 2 || trussesMountDivisions[targetIndex] === 4)
              && (trussesBracingTypes[targetIndex] === "A" || trussesBracingTypes[targetIndex] === "B"))
          ) && "Sim";

        return field === "isSuperiorHorizontalDifferent"
          ? currentValues.updateHorizontal(inputValues[targetIndex].segmentId, field, isSuperiorHorizontalDifferent ?? "")
          : currentValues.updateHorizontal(inputValues[targetIndex].segmentId, field, "");
      });
      currentValues.updateHorizontal(inputValues[targetIndex].segmentId, "superiorHorizontalData", "empty");
    } else {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetIndex];
        newChecked[targetIndex] = [...newChecked[targetIndex], selectedSegmentIndex];
        return newChecked;
      });

      fieldsToUpdate.forEach(field => {
        if (!(trussesTypes[targetIndex] === "Treliça X"
          && (trussesMountDivisions[targetIndex] === 2 || trussesMountDivisions[targetIndex] === 4)
          && (trussesBracingTypes[targetIndex] === "A" && trussesBracingTypes[targetIndex] === "B"))) {
          currentValues.updateHorizontal(
            inputValues[targetIndex].segmentId, "isSuperiorHorizontalDifferent", "Sim");
          reinforcementFieldsToUpdate.forEach(f => currentValues.updateSuperiorHorizontal(
            inputValues[targetIndex].segmentId, f, inputValues[selectedSegmentIndex].superiorHorizontalData[f]));
        } else {
          if (trussesWithSuperiorHorizontals[targetIndex] === "Sim") {
            currentValues.updateHorizontal(inputValues[targetIndex].segmentId, field, inputValues[selectedSegmentIndex][field]);
            if (field === "isSuperiorHorizontalDifferent" && inputValues[selectedSegmentIndex].isSuperiorHorizontalDifferent === "Sim") {
              reinforcementFieldsToUpdate.forEach(f => currentValues.updateSuperiorHorizontal(
                inputValues[targetIndex].segmentId, f, inputValues[selectedSegmentIndex].superiorHorizontalData[f])
              );
            }
          } else {
            field !== "isSuperiorHorizontalDifferent"
              ? currentValues.updateHorizontal(inputValues[targetIndex].segmentId, field, inputValues[selectedSegmentIndex][field])
              : currentValues.updateHorizontal(inputValues[targetIndex].segmentId, field, "NA");
          }
        }
      });
    }
    
    setInputValues(currentValues.horizontals);
  };

  async function updateHorizontalsData() {
    const horizontalsModel = new HorizontalsModel(inputValues);
    if (!horizontalsModel.validate(trussesWithSuperiorHorizontals)) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveHorizontals(horizontalsModel.toJSON(), towerId, userData.token);
      const updatedModel = new HorizontalsModel(updatedData);
      setHorizontalSelected(updatedModel.horizontals);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das horizontais atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das horizontais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Horizontais"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateHorizontalsData}
      >
        <TowerHorizontalLeftMenu
          nSegments={nSegments}
          trussesWithSuperiorHorizontals={trussesWithSuperiorHorizontals}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          handleInputChange={(field, value, index) => handleInputChange(field, value, index)}
          checkedSegments={checkedSegments}
          setEqualSegment={(index, checked) => setEqualSegment(index, checked)}
          trussesTypes={trussesTypes}
          trussesBracingTypes={trussesBracingTypes}
          trussesMountDivisions={trussesMountDivisions}
          hasMainHorizontal={hasMainHorizontal}
        />
      </LeftMenu>
      <RightContainer>
        <TowerHorizontalRightContainer 
          trussesWithSuperiorHorizontals={trussesWithSuperiorHorizontals}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          handleSuperiorHorizontalChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
          trussesTypes={trussesTypes}
          trussesSelected={trussesSelected}
          towerImages={towerImages}
          trussesBracingTypes={trussesBracingTypes}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
