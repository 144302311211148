import trussImages from "../assets/images/towerImages/trussTypes/trusses-images";

const getTowerImages = (trussesSelected) => {
  if (!trussesSelected || trussesSelected?.length === 0) return null;

  return trussesSelected?.map((truss) => {
    if (!truss?.trussType || !truss?.mountDivisions || !truss?.bracingType) return null;

    const imageName = truss?.hasSuperiorHorizontal === "Sim" && truss.trussType !== "Treliça K"
      ? `${truss.trussType.charAt(truss.trussType.length - 1)}${truss.mountDivisions}${truss.bracingType}_HS`
      : `${truss.trussType.charAt(truss.trussType.length - 1)}${truss.mountDivisions}${truss.bracingType}`;

    return trussImages[truss.trussType][imageName];
  });
};

export default getTowerImages;
