import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import CustomCheckbox from "../../common/custom-checkbox";
import { horizontalLockingsInfo } from "../../../constants/tower-trusses-data";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import ChangeSegmentButtonsContainer from "../../common/change-segment-button";
import React from "react";

export default function TowerHorizontalLockingLeftMenu({
  nSegments,
  nBars,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  horizontalSelectedInMenu,
  setHorizontalSelectedInMenu,
  barSelectedInMenu,
  setBarSelectedInMenu,
  handleInputChange,
  isFieldEnabled,
  trussesWithHorizontals,
  sectionType,
  trussesTypes,
}) {
  const currentSegmentIndex = segmentSelectedInMenu === ""
    ? segmentSelectedInMenu : Number(segmentSelectedInMenu - 1);
  const currentHorizontal = currentSegmentIndex !== "" && horizontalSelectedInMenu !== ""
    && inputValues[segmentSelectedInMenu - 1]
    ? inputValues[segmentSelectedInMenu - 1][horizontalSelectedInMenu] : null;

  return (
    <>
      <InputMenu>
        <div>
          <div>
            <h2>Trecho Atual</h2>
            <select
              className="highlightColor"
              value={segmentSelectedInMenu}
              onChange={(e) => setSegmentSelectedInMenu(e.target.value)}>
              <option value="" key={"segment-empty"}>Trecho atual</option>
              {[...Array(nSegments).keys()].map((i) => (
                <option value={i + 1} key={`segment-${i + 1}`}>
                  Trecho {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div>
            <h2>Horizontal</h2>
            <select
              className={segmentSelectedInMenu === "" ? null : "highlightColor"}
              disabled={segmentSelectedInMenu === ""}
              value={horizontalSelectedInMenu}
              onChange={(e) => setHorizontalSelectedInMenu(e.target.value)}>
              <option value="" key={"horizontal-empty"}>Selecione</option>
              {trussesTypes[segmentSelectedInMenu - 1] !== "Treliça V" &&
                <option value="mainHorizontalData" key={"mainHorizontalData"}>Principal</option>}
              {trussesWithHorizontals[segmentSelectedInMenu - 1] === "Sim" &&
                <option value="superiorHorizontalData" key={"superiorHorizontalData"}>Superior</option>}
            </select>
          </div>
        </div>
      </InputMenu>

      <ChangeSegmentButtonsContainer
        segmentSelectedInMenu={segmentSelectedInMenu}
        setSegmentSelectedInMenu={setSegmentSelectedInMenu}
        nSegments={nSegments}
        previousButtonText={"Trecho anterior"}
        nextButtonText={"Próximo trecho"}
      />

      <InputMenu>
        <h2>Existe Travamento na Horizontal?</h2>
        <select
          disabled={segmentSelectedInMenu === "" || horizontalSelectedInMenu === ""}
          value={(segmentSelectedInMenu === "" || horizontalSelectedInMenu === "")
            ? "" : currentHorizontal?.hasHorizontalLocking ?? ""}
          onChange={(e) => handleInputChange("hasHorizontalLocking", e.target.value)}
        >
          <option value="" key={"hasHorizontalLocking-empty"}>Selecione</option>
          <option value="Sim" key={"yes"}>Sim</option>
          <option value="Não" key={"no"}>Não</option>
        </select>
      </InputMenu>

      {
        isFieldEnabled() &&
        <>
          <InputMenu>
            <h2>Escolha o tipo</h2>
            <select
              value={currentHorizontal?.lockingType ?? ""}
              onChange={(e) => handleInputChange("lockingType", e.target.value)}
            >
              <option value="" key={"lockingType-empty"}>Escolha o tipo de travamento</option>
              {horizontalLockingsInfo[sectionType]?.map((type) => (
                <option value={type.lockingType} key={type.lockingType}>{type.lockingType}</option>
              ))}
            </select>
          </InputMenu>

          <InputMenu>
            <div>
              <div>
                <h2>Barra</h2>
                <select
                  value={isFieldEnabled() ? barSelectedInMenu : ""}
                  onChange={(e) => setBarSelectedInMenu(e.target.value)}
                >
                  <option value="" key={"bar-empty"}>Selecione a barra de travamento</option>
                  {[...Array(nBars[horizontalSelectedInMenu][segmentSelectedInMenu - 1]).keys()]
                    ?.map(index => (
                      <option value={index + 1} key={`bar-${index + 1}`}>Barra {index + 1}</option>
                    ))}
                </select>
              </div>
              <CheckboxContainer>
                <CustomCheckbox
                  id={`bar-segment${barSelectedInMenu - 1}`}
                  disabled={barSelectedInMenu === ""}
                  label={"Barra Faltante"}
                  checked={!isFieldEnabled() && barSelectedInMenu === ""
                    ? false : currentHorizontal?.bars[barSelectedInMenu - 1]?.isMissingBar || false}
                  onChange={(e) => handleInputChange("isMissingBar")}
                />
              </CheckboxContainer>
            </div>
          </InputMenu>
        </>
      }

      {
        (isFieldEnabled() && barSelectedInMenu !== ""
          && currentHorizontal?.bars[barSelectedInMenu - 1]?.isMissingBar !== true) &&
        <>
          <InputMenu>
            <h2>Tipo de Perfil</h2>
            <select
              value={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType ?? ""}
              onChange={(e) => handleInputChange("profileType", e.target.value)}
            >
              <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
              {profileTypes.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
            </select>
          </InputMenu>

          <InputMenu>
            <h2>Aço do Perfil</h2>
            <select
              value={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileSteel ?? ""}
              onChange={(e) => handleInputChange("profileSteel", e.target.value)}
            >
              <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
              {currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType
                && handleProfileSteelTypes(currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType)
                  ?.map( (type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
            </select>
          </InputMenu>

          <InputMenu>
            <h2>Dimensões do Perfil</h2>
            {
              currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType === "L - Cantoneira" &&
              <AutocompleteInput
                inputValue={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileDimensions || ""}
                handleInputChange={(field, value) => handleInputChange(field, value)}
                list={handleProfileDimensions(currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType) || []}
                field={"profileDimensions"}
                placeholder={"Selecione as dimensões do perfil"}
              />
            }
            {
              currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType === "T - Tubo Vazado" &&
              <div>
                <AutocompleteInput
                  inputValue={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileDiameter || ""}
                  handleInputChange={(field, value) => handleInputChange(field, value)}
                  list={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileSteel
                    ? handleProfileDimensions(currentHorizontal?.bars[barSelectedInMenu - 1].profileType,
                      currentHorizontal?.bars[barSelectedInMenu - 1]?.profileSteel) : []}
                  field={"profileDiameter"}
                  placeholder={"Diâmetro"}
                  label={"Diâmetro"}
                />

                <AutocompleteInput
                  inputValue={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileThickness || ""}
                  handleInputChange={(field, value) => handleInputChange(field, value)}
                  list={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileDiameter
                    ? tubeProfileData[currentHorizontal?.bars[barSelectedInMenu - 1]?.profileSteel]
                      [currentHorizontal?.bars[barSelectedInMenu - 1]?.profileDiameter] : []}
                  field={"profileThickness"}
                  placeholder={"Espessura"}
                  label={"Espessura"}
                />
              </div>
            }
            {
              currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType === "BR - Barra Maciça" &&
              <AutocompleteInput
                inputValue={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileDiameter || ""}
                handleInputChange={(field, value) => handleInputChange(field, value)}
                list={handleProfileDimensions(currentHorizontal?.bars[barSelectedInMenu - 1].profileType)}
                field={"profileDiameter"}
                placeholder={"Selecione o diâmetro do perfil"}
              />
            }
            {
              currentHorizontal?.bars[barSelectedInMenu - 1]?.profileType === "U - Perfil U" &&
              <>
                <div>
                  <CustomInput
                    placeholder={"Aba (mm)"}
                    label={"Aba"}
                    value={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileFlange ?? ""}
                    onChange={(newValue) => handleInputChange("profileFlange", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />

                  <CustomInput
                    placeholder={"Alma (mm)"}
                    label={"Alma"}
                    value={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileWeb ?? ""}
                    onChange={(newValue) => handleInputChange("profileWeb", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />
                </div>
                <div>
                  <AutocompleteInput
                    inputValue={currentHorizontal?.bars[barSelectedInMenu - 1]?.profileThickness || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={handleProfileDimensions(currentHorizontal?.bars[barSelectedInMenu - 1].profileType) || []}
                    field={"profileThickness"}
                    placeholder={"Selecione a espessura do perfil"}
                    label={"Espessura"}
                  />
                </div>
              </>
            }
          </InputMenu>

          <InputMenu>
            <h2>Aço dos Parafusos</h2>
            <select
              value={currentHorizontal?.bars[barSelectedInMenu - 1]?.boltsSteel ?? ""}
              onChange={(e) => handleInputChange("boltsSteel", e.target.value)}
            >
              <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
              {boltsSteel.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
            </select>
          </InputMenu>

          <InputMenu>
            <h2>Parafusos de Conexão</h2>
            <div>
              <CustomInput
                placeholder={"Quantidade"}
                label={"Quantidade"}
                value={currentHorizontal?.bars[barSelectedInMenu - 1]?.numConnectionBolts ?? ""}
                onChange={(newValue) => handleInputChange("numConnectionBolts", newValue)}
                toFixed={0}
              />

              <CustomSelect
                value={currentHorizontal?.bars[barSelectedInMenu - 1]?.diameterConnectionBolts ?? ""}
                label={"Diâmetro"}
                onChange={(e) => handleInputChange("diameterConnectionBolts", e)}
              >
                <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                {boltsDiameter.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </CustomSelect>
            </div>
          </InputMenu>
        </>
      }
    </>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 0;
`;
