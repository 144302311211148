import React from "react";
import TowerAntennasTable from "./tower_antennasTable";
import TowerAntennasAEVTable from "./tower_antennasAEVTable";
import styled from "styled-components";
import antennas_images from "../../../assets/images/antennasImages/index";
import colors from "../../../constants/color-constants";

export default function TowerAntennaRightContainer({
  antennaGroupSelectedInMenu,
  inputValues,
}) {
  const columnTitles = ["ANTENA", "OPERADORA", "CONDIÇÃO", "TIPO", "MODELO", "QUANTIDADE",
    "DIMENSÕES (mm)", "ALTURA (m)", "COEF. ARRASTO", "ÁREA (m²)", "ÁREA + C.A.(m²)", "CARGA (kgf)"];

  const conditionsColors = {
    existing: colors.kcGreen,
    remove: colors.kcRed,
    install: colors.kcBlue,
    reserve: colors.kcDarkGray
  };

  const generateTableContent = () => {
    if (!inputValues?.antennas) return [];
    return Array.from(inputValues.antennas, (group, index) => [
      group.groupName ?? "",
      group.operator ?? "",
      group.condition ?? "",
      group.type ?? "",
      group.model ?? "",
      group.qtdAntennas ?? "",
      formatDimensions(group),
      group.fixingHeight ? Number(group.fixingHeight)?.toFixed(2) : "",
      group.dragCoef ?? "",
      group.area ? Number(group.area)?.toFixed(2) : "",
      group.areaAndDragCoef ? Number(group.areaAndDragCoef)?.toFixed(2) : "",
      group.load ? Number(group.load)?.toFixed(2) : "",
    ]);
  };

  const formatDimensions = (group) => {
    let string = [];
    if (group.diameterDimension)
      string.push(`ø ${group.diameterDimension}`);
    if (group.widthDimension)
      string.push(`${group.widthDimension}`);
    if (group.heightDimension)
      string.push(`${group.heightDimension}`);

    return string.join(" x ");
  };

  const calculateTotals = () => {
    const totals = {
      area: 0,
      areaAndDragCoef: 0,
      load: 0,
    };

    if (inputValues?.antennas) {
      inputValues.antennas.forEach(group => {
        totals.area += parseFloat(group.area) || 0;
        totals.areaAndDragCoef += parseFloat(group.areaAndDragCoef) || 0;
        totals.load += parseFloat(group.load) || 0;
      });
    }

    return totals;
  };

  const calculateAEVTotals = () => {
    const totals = {
      existing: 0,
      remove: 0,
      install: 0,
      reserve: 0,
      all: 0
    };

    if (!inputValues?.antennas) return totals;

    inputValues.antennas.forEach(group => {
      if (group.condition === "Existente" || group.condition === "Retirar")
        totals.existing += parseFloat(group.areaAndDragCoef) || 0;
      if (group.condition === "Retirar")
        totals.remove += parseFloat(group.areaAndDragCoef) || 0;
      if (group.condition === "Instalar")
        totals.install += parseFloat(group.areaAndDragCoef) || 0;
      if (group.condition === "Reserva")
        totals.reserve += parseFloat(group.areaAndDragCoef) || 0;
      totals.all += parseFloat(group.areaAndDragCoef) || 0;
    });

    return totals;
  };

  function getSelectedAntennaImage() {
    const selectedGroup = inputValues.antennas.find(group => group.groupName === antennaGroupSelectedInMenu)?.type;
    if (!selectedGroup) return;

    const imageSrc = selectedGroup.replace(" ", "_");
    return antennas_images[imageSrc];
  }

  return (
    <Container>
      <h2>Tabela de Antenas</h2>

      <TowerAntennasTable
        columnTitles={columnTitles}
        conditionsColors={conditionsColors}
        content={generateTableContent()}
        totals={calculateTotals()}
      />

      <div className={"bottom-container"}>
        {getSelectedAntennaImage()
          ? <AntennaImage>
            <h3>{`Antena ${antennaGroupSelectedInMenu}`}</h3>
            <div className={"image"}>
              <img src={getSelectedAntennaImage()} alt={"Imagem Antena"}/>
            </div>
          </AntennaImage>
          : <EmptyContainer></EmptyContainer>
        }

        <TowerAntennasAEVTable
          inputValues={inputValues}
          totals={calculateAEVTotals()}
          conditionsColors={conditionsColors}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 14px;
  overflow-y: hidden;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 20px;
    font-weight: 500;
  }
  
  .bottom-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const AntennaImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  max-width: 460px;
  height: 290px;
  border: 1px solid ${colors.kcBorderGray};
  border-radius: 6px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    border-bottom: 1px solid ${colors.kcBorderGray};
    background-color: #DADADA;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
  }
  
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: min-content;
    padding: 10px;
    box-sizing: border-box;
  }

  img {
    width: 215px;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
`;
