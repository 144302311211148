import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import MountLeftMenu from "./tower_mountLeftMenu";
import TowerMountRightContainer from "./tower_mountRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerMounts } from "../../../hooks/api/tower-api/useTowerMounts";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MountsModel from "../../../models/MountModel";

export default function TowerMountPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected, mountSelected, setMountSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchMounts } = useTowerMounts("get");
  const { act: saveMounts } = useTowerMounts("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const segments = nSegments > 0
    ? Array.from({ length: nSegments }, (_, i) => `Trecho ${i + 1}`) : [];

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [hasReinforcement, setHasReinforcement] = useState(false);
  const [checkedSegments, setCheckedSegments] = useState(Array.from({ length: nSegments }, (_, i) => []));
  const [checkedConnections, setCheckedConnections] = useState(Array.from({ length: nSegments }, (_, i) => []));
  const [inputValues, setInputValues] = useState(new MountsModel(mountSelected).mounts);

  useEffect(() => {
    loadMountsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const mountsModel = MountsModel.fromJSON(JSON.stringify(response));
        setMountSelected(mountsModel.mounts);
        setInputValues(mountsModel.mounts);
      }
    });
  }, [towerId, userData.token]);

  const loadMountsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchMounts(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados de montantes.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchMounts, towerId, userData.token]);

  function setInitialEmptyValues() {
    const initialData = new MountsModel(
      [...Array(nSegments)].map((_, i) => ({
        segmentId: segmentsSelected[i].segmentId || "",
        profileType: mountSelected[i]?.profileType || "",
        profileSteel: mountSelected[i]?.profileSteel || "",
        profileDimensions: mountSelected[i]?.profileDimensions || "",
        profileDiameter: mountSelected[i]?.profileDiameter || "",
        profileThickness: mountSelected[i]?.profileThickness || "",
        profileFlange: mountSelected[i]?.profileFlange || "",
        profileWeb: mountSelected[i]?.profileWeb || "",
        mountConnection: mountSelected[i]?.mountConnection || "",
        numConnectionBolts: mountSelected[i]?.numConnectionBolts || "",
        diameterConnectionBolts: mountSelected[i]?.diameterConnectionBolts || "",
        boltsSteel: mountSelected[i]?.boltsSteel || "",
        hasReinforcement: mountSelected[i]?.hasReinforcement || "",
        reinforcementData: mountSelected[i]?.reinforcementData || {},
      }))
    );
    setInputValues(initialData.mounts);
  }

  const fieldsToCheck = ["profileType", "profileSteel", "profileDimensions", "profileDiameter", 
    "profileThickness", "profileFlange", "profileWeb", "mountConnection", "boltsSteel", 
    "numConnectionBolts", "diameterConnectionBolts", "hasReinforcement", "reinforcementData.profileType", 
    "reinforcementData.profileSteel", "reinforcementData.profileDimensions", 
    "reinforcementData.profileDiameter", "reinforcementData.profileThickness", 
    "reinforcementData.profileFlange", "reinforcementData.profileWeb", 
    "reinforcementData.mountConnection", "reinforcementData.boltsSteel", 
    "reinforcementData.numConnectionBolts", "reinforcementData.diameterConnectionBolts"
  ];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], mountSelected, reloadProject, fieldsToCheck);

  const updateHasReinforcement = (selectedSegment) => {
    const reinforcement = inputValues[selectedSegment - 1]?.hasReinforcement;
    setHasReinforcement(reinforcement === "Sim");
  };

  useEffect(() => {
    updateHasReinforcement(segmentSelectedInMenu);
  }, [segmentSelectedInMenu, inputValues]);

  const handleInputChange = useCallback((field, value) => {
    if (field === "hasReinforcement") setHasReinforcement(value === "Sim");

    const mountsModel = new MountsModel(inputValues);
    mountsModel.updateMount(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(mountsModel.mounts);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const handleReinforcementChange = useCallback((field, value) => {
    const mountsModel = new MountsModel(inputValues);
    mountsModel.updateReinforcementField(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(mountsModel.mounts);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const setEqualSegment = (isConnection, targetIndex) => {
    const currentValues = new MountsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;

    const fieldsToUpdate = isConnection
      ? ["mountConnection", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts"]
      : ["profileType", "profileSteel", "profileDimensions", "profileDiameter", "profileThickness",
        "profileFlange", "profileWeb", "hasReinforcement", "reinforcementData"];
    
    if (!isConnection) {
      if (checkedSegments[selectedSegmentIndex].includes(targetIndex)) {
        setCheckedSegments(prev => {
          const newChecked = [...prev];
          newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetIndex);
          newChecked[targetIndex] = newChecked[targetIndex].filter(i => i !== selectedSegmentIndex);
          return newChecked;
        });
        fieldsToUpdate.forEach(field => {
          currentValues.updateMount(inputValues[targetIndex].segmentId, field, "");
        });
      } else {
        setCheckedSegments(prev => {
          const newChecked = [...prev];
          newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetIndex];
          newChecked[targetIndex] = [...newChecked[targetIndex], selectedSegmentIndex];
          return newChecked;
        });
        fieldsToUpdate.forEach(field => {
          if (field === "reinforcementData") {
            Object.keys(currentValues.mounts[selectedSegmentIndex].reinforcementData).forEach(reinforceField => {
              currentValues.updateReinforcementField(inputValues[targetIndex].segmentId, reinforceField,
                currentValues.mounts[selectedSegmentIndex].reinforcementData[reinforceField]);
            });
          } else {
            currentValues.updateMount(inputValues[targetIndex].segmentId, field, currentValues.mounts[selectedSegmentIndex][field]);
          }
        });
      }
    } else {
      if (checkedConnections[selectedSegmentIndex].includes(targetIndex)) {
        setCheckedConnections(prev => {
          const newChecked = [...prev];
          newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetIndex);
          newChecked[targetIndex] = newChecked[targetIndex].filter(i => i !== selectedSegmentIndex);
          return newChecked;
        });
        fieldsToUpdate.forEach(field => {
          currentValues.updateMount(inputValues[targetIndex].segmentId, field, "");
        });
        if (checkedSegments[selectedSegmentIndex].includes(targetIndex)) {
          currentValues.updateMount(inputValues[targetIndex].segmentId, "mountConnection", "Não há");
        }
      } else {
        setCheckedConnections(prev => {
          const newChecked = [...prev];
          newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetIndex];
          newChecked[targetIndex] = [...newChecked[targetIndex], selectedSegmentIndex];
          return newChecked;
        });
        fieldsToUpdate.forEach(field => {
          currentValues.updateMount(inputValues[targetIndex].segmentId, field, currentValues.mounts[selectedSegmentIndex][field]);
        });
      }
    }

    setInputValues(currentValues.mounts);
  };
   
  async function updateMountsData() {
    const mountsModel = new MountsModel(inputValues);
    if (!mountsModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveMounts(mountsModel.toJSON(), towerId, userData.token);
      setMountSelected(updatedData.mounts);
      setInputValues(updatedData.mounts);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações dos montantes atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados de montantes.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Montantes"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateMountsData}
      >
        <MountLeftMenu
          nSegments={nSegments}
          segments={segments}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          setEqualSegment={setEqualSegment}
          checkedSegments={checkedSegments}
          checkedConnections={checkedConnections}
        />
      </LeftMenu>
      <RightContainer>
        <TowerMountRightContainer 
          nSegments={nSegments}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          hasReinforcement={hasReinforcement} 
          handleReinforcementChange={handleReinforcementChange}
          trussesSelected={trussesSelected}
          towerImages={towerImages}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
