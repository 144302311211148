import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerTrussesLeftMenu from "./tower-trussesLeftMenu";
import TowerTrussesRightContainer from "./tower-trussesRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerTrusses } from "../../../hooks/api/tower-api/useTowerTrusses";
import { useTowerSegments } from "../../../hooks/api/tower-api/useTowerSegments";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trussesInfo } from "../../../constants/tower-trusses-data";
import TrussesModel from "../../../models/TrussModel";
import getTowerImages from "../../../utils/setTowerImages";

export default function TowerTrussesPage() {
  const { userData } = useContext(UserContext);
  const { towerId, setTowerImages, trussesSelected, setTrussesSelected, geometrySelected, setSegmentsSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchTrusses } = useTowerTrusses("get");
  const { act: saveTrusses } = useTowerTrusses("post");
  const { act: fetchSegmentsId } = useTowerSegments("get");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [inputValues, setInputValues] = useState(new TrussesModel(trussesSelected).trusses);

  useEffect(() => {
    if (towerId && userData.token) {
      initializeData();
    }
  }, [towerId, userData.token]);

  const loadSegments = useCallback(async() => {
    try {
      const segments = await fetchSegmentsId(towerId, userData.token);
      if (!segments || segments.length === 0) return [];
      setSegmentsSelected(segments);
      return segments;
    } catch (error) {
      toast.error("Erro ao buscar dados dos trechos.");
      return [];
    }
  }, [fetchSegmentsId, towerId, userData.token, setSegmentsSelected]);

  const loadTrusses = useCallback(async() => {
    try {
      const trusses = await fetchTrusses(towerId, userData.token);
      return trusses || [];
    } catch (error) {
      toast.error("Erro ao buscar dados de treliçamento.");
      return [];
    }
  }, [fetchTrusses, towerId, userData.token]);

  const initializeData = useCallback(async() => {
    setIsLoading(true);
    try {
      const segmentsId = await loadSegments();
      const trussesData = await loadTrusses();
      setInitialValues(segmentsId, trussesData);
    } catch (error) {
      toast.error("Erro ao buscar dados de treliçamento.");
    } finally {
      setIsLoading(false);
    }
  }, [towerId, userData.token, loadSegments, loadTrusses]);

  function setInitialValues(segmentsId, trussesData) {
    const initialData = new TrussesModel(
      [...Array(nSegments)].map((_, i) => ({
        segmentId: segmentsId[i]?.segmentId || "",
        trussType: trussesData[i]?.trussType ?? "",
        totalHeight: trussesData[i]?.totalHeight ?? "",
        hasSuperiorHorizontal: trussesData[i]?.hasSuperiorHorizontal ?? "",
        mountDivisions: trussesData[i]?.mountDivisions ?? "",
        bracingType: trussesData[i]?.bracingType ?? "",
      }))
    );
    setInputValues(initialData.trusses);
  }

  const trussesTypes = Object.keys(trussesInfo);
  const fieldsToCheck = ["trussType", "totalHeight", "hasSuperiorHorizontal", "mountDivisions", "bracingType"];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], trussesSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value) => {
    const trussesModel = new TrussesModel(inputValues);
    trussesModel.updateTruss(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(trussesModel.trusses);
  }, [inputValues, segmentSelectedInMenu]);

  async function updateTrussesData() {
    const trussesModel = new TrussesModel(inputValues);
    if (!trussesModel.validate(geometrySelected.totalHeight)) return false;
    setTowerImages(getTowerImages(trussesModel.trusses));

    setIsLoading(true);
    try {
      const updatedData = await saveTrusses(trussesModel.toJSON(), towerId, userData.token);
      setTrussesSelected(new TrussesModel(updatedData).trusses);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das treliças atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das treliças.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Treliçamento"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateTrussesData}
      >
        <TowerTrussesLeftMenu
          nSegments={nSegments}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          handleInputChange={handleInputChange}
          trussesTypes={trussesTypes}
          trussesInfo={trussesInfo}
        />
      </LeftMenu>
      <RightContainer>
        <TowerTrussesRightContainer
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          handleInputChange={handleInputChange}
          trussesTypes={trussesTypes}
          trussesInfo={trussesInfo}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
