import React from "react";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import ChangeSegmentButtonsContainer from "../../common/change-segment-button";

export default function TowerTrussesLeftMenu({
  nSegments,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  handleInputChange,
  trussesTypes,
  trussesInfo,
}) {
  function handleMountDivisionsOptions(trussType) {
    if (!trussType) return [];
    return Object.keys(trussesInfo[trussType]);
  }

  function handleBracingTypesOptions(trussType, mountDivision) {
    if (!mountDivision) return [];
    return trussesInfo[trussType][mountDivision].map(type => type.bracingType);
  }

  return(
    <>
      <InputMenu>
        <h2>Trecho Atual</h2>
        <select
          className="highlightColor"
          value={segmentSelectedInMenu}
          onChange={(e) => setSegmentSelectedInMenu(isNaN(Number(e.target.value))
            ? Number(e.target.value) : e.target.value)}>
          <option value="" key={"segment-empty"}>Selecione um trecho</option>
          {[...Array(nSegments).keys()].map((i) => (
            <option value={i + 1} key={`trecho-${i + 1}`}>
              Trecho {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      <ChangeSegmentButtonsContainer
        segmentSelectedInMenu={segmentSelectedInMenu}
        setSegmentSelectedInMenu={setSegmentSelectedInMenu}
        nSegments={nSegments}
        previousButtonText={"Trecho anterior"}
        nextButtonText={"Próximo trecho"}
      />

      <InputMenu>
        <h2>Tipo de Treliça</h2>
        <select
          disabled={segmentSelectedInMenu===""}
          value={segmentSelectedInMenu==="" ? "" : inputValues[segmentSelectedInMenu - 1]?.trussType || ""}
          onChange={(e) => handleInputChange("trussType", e.target.value)}
        >
          <option value="" key={"trussType-empty"}>Selecione o tipo de treliça</option>
          {trussesTypes.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>{"Altura Total (mm)"}</h2>
        <CustomInput
          disabled={segmentSelectedInMenu===""}
          name={"totalHeight"}
          placeholder={"Informe a altura do trecho"}
          value={inputValues[segmentSelectedInMenu - 1]?.totalHeight || ""}
          onChange={(newValue) => handleInputChange("totalHeight", newValue)}
          isNumber={true}
          unit="mm"
        />
      </InputMenu>

      <InputMenu>
        <h2>Tem Horizontal Superior?</h2>
        <select
          disabled={segmentSelectedInMenu === ""
            || inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K"
            || inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
            || (inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
              && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B")
          }
          value={segmentSelectedInMenu === "" ? "" : inputValues[segmentSelectedInMenu - 1]?.hasSuperiorHorizontal || ""}
          onChange={(e) => handleInputChange("hasSuperiorHorizontal", e.target.value)}
        >
          <option value="" key={"hasSuperiorHorizontal-empty"}>Informe se existe horizontal superior</option>
          <option value="Sim" key={"yes"}>Sim</option>
          <option value="Não" key={"no"}>Não</option>
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Divisões do Montante</h2>
        <select
          disabled={segmentSelectedInMenu===""}
          value={segmentSelectedInMenu==="" ? "" : inputValues[segmentSelectedInMenu - 1]?.mountDivisions || ""}
          onChange={(e) => handleInputChange("mountDivisions", Number(e.target.value))}>
          <option value="" key={"mountDivisions-empty"}>Informe o nº de divisões do montante</option>
          {inputValues[segmentSelectedInMenu - 1]?.trussType &&
            handleMountDivisionsOptions(inputValues[segmentSelectedInMenu - 1].trussType).map(type => (
              <option value={(type)} key={type}>{type}</option>
            ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Tipo de Contraventamento</h2>
        <select
          disabled={segmentSelectedInMenu===""}
          placeholder="Selecione o tipo de contraventamento"
          value={segmentSelectedInMenu==="" ? "" : inputValues[segmentSelectedInMenu - 1]?.bracingType || ""}
          onChange={(e) => handleInputChange("bracingType", e.target.value)}
        >
          <option value="" key={"bracingType-empty"}>Selecione o tipo de contraventamento</option>
          {inputValues[segmentSelectedInMenu - 1]?.mountDivisions &&
            handleBracingTypesOptions(inputValues[segmentSelectedInMenu - 1].trussType,
              inputValues[segmentSelectedInMenu - 1].mountDivisions).map(type => (
              <option value={type} key={type}>Tipo {type}</option>
            ))}
        </select>
      </InputMenu>
    </>
  );
}
