import H1A from "./trusses-H/H1A.svg";
import H1B from "./trusses-H/H1B.svg";
import H1A_HS from "./trusses-H/H1A_HS.svg";

import Z1A from "./trusses-Z/Z1A.svg";
import Z1A_HS from "./trusses-Z/Z1A_HS.svg";

import X1A from "./trusses-X/X1A.svg";
import X2A from "./trusses-X/X2A.svg";
import X2B from "./trusses-X/X2C.svg";
import X2C from "./trusses-X/X2D.svg";
import X3A from "./trusses-X/X3A.svg";
import X3B from "./trusses-X/X3B.svg";
import X4A from "./trusses-X/X4A.svg";
import X4B from "./trusses-X/X4D.svg";
import X4C from "./trusses-X/X4G.svg";
import X1A_HS from "./trusses-X/X1A_HS.svg";
import X2A_HS from "./trusses-X/X2A_HS.svg";
import X2B_HS from "./trusses-X/X2C_HS.svg";
import X2C_HS from "./trusses-X/X2D_HS.svg";
import X3A_HS from "./trusses-X/X3A_HS.svg";
import X3B_HS from "./trusses-X/X3B_HS.svg";
import X4A_HS from "./trusses-X/X4A_HS.svg";
import X4B_HS from "./trusses-X/X4D_HS.svg";
import X4C_HS from "./trusses-X/X4G_HS.svg";

import K1A from "./trusses-K/K1A.svg";
import K1B from "./trusses-K/K1B.svg";
import K2A from "./trusses-K/K2A.svg";
import K2B from "./trusses-K/K2B.svg";
import K2C from "./trusses-K/K2C.svg";
import K3A from "./trusses-K/K3A.svg";
import K3B from "./trusses-K/K3C.svg";
import K3C from "./trusses-K/K3D.svg";
import K4A from "./trusses-K/K4A.svg";
import K4B from "./trusses-K/K4C.svg";
import K4C from "./trusses-K/K4E.svg";
import K5A from "./trusses-K/K5A.svg";
import K5B from "./trusses-K/K5F.svg";
import K6A from "./trusses-K/K6A.svg";
import K6B from "./trusses-K/K6C.svg";
import K6D from "./trusses-K/K6D.svg";
import K6E from "./trusses-K/K6E.svg";

import V1A from "./trusses-V/V1A.svg";
import V1B from "./trusses-V/V1B.svg";
import V2A from "./trusses-V/V2A.svg";
import V2B from "./trusses-V/V2C.svg";
import V3A from "./trusses-V/V3A.svg";
import V3B from "./trusses-V/V3D.svg";
import V4A from "./trusses-V/V4A.svg";
import V5A from "./trusses-V/V5A.svg";
import V6A from "./trusses-V/V6A.svg";
import V1A_HS from "./trusses-V/V1A_HS.svg";
import V1B_HS from "./trusses-V/V1B_HS.svg";
import V2A_HS from "./trusses-V/V2A_HS.svg";
import V2B_HS from "./trusses-V/V2C_HS.svg";
import V3A_HS from "./trusses-V/V3A_HS.svg";
import V3B_HS from "./trusses-V/V3D_HS.svg";
import V4A_HS from "./trusses-V/V4A_HS.svg";
import V5A_HS from "./trusses-V/V5A_HS.svg";
import V6A_HS from "./trusses-V/V6A_HS.svg";

const trussH = { H1A, H1B, H1A_HS };
const trussZ = { Z1A, Z1A_HS };
const trussX = { X1A, X2A, X2B, X2C, X3A, X3B, X4A, X4B, X4C,
  X1A_HS, X2A_HS, X2B_HS, X2C_HS, X3A_HS, X3B_HS, X4A_HS, X4B_HS, X4C_HS };
const trussK = { K1A, K1B, K2A, K2B, K2C, K3A, K3B, K3C, K4A, K4B, K4C, K5A, K5B, K6A, K6B, K6D, K6E };
const trussV = { V1A, V1B, V2A, V2B, V3A, V3B, V4A, V5A, V6A,
  V1A_HS, V1B_HS, V2A_HS, V2B_HS, V3A_HS, V3B_HS, V4A_HS, V5A_HS, V6A_HS };

const trussImages = {
  "Treliça H": trussH,
  "Treliça Z": trussZ,
  "Treliça X": trussX,
  "Treliça K": trussK,
  "Treliça V": trussV,
};

export default trussImages;
