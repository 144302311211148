import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import UserContext from "../../../contexts/UserContext";
import { TowerContext } from "../../../contexts/TowerContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { useTowerAntennas } from "../../../hooks/api/tower-api/useTowerAntennas";
import useGetAllAntennas from "../../../hooks/api/useGetAllAntennas";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import TowerAntennaLeftMenu from "./tower_antennasLeftMenu";
import TowerAntennaRightContainer from "./tower_antennasRightContainer";
import AntennasModel from "../../../models/AntennaModel";
import { toast } from "react-toastify";

export default function TowerAntennasPage() {
  const { userData } = useContext(UserContext);
  const { towerId, geometrySelected, windSelected, antennaSelected, setAntennaSelected } = useContext(TowerContext);
  const { setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchAntennas } = useTowerAntennas("get");
  const { act: saveAntennas } = useTowerAntennas("post");
  const { getAllAntennas } = useGetAllAntennas();

  const [antennaGroups, setAntennaGroups] = useState([]);
  const [antennaGroupSelectedInMenu, setAntennaGroupSelectedInMenu] = useState("");
  const [inputValues, setInputValues] = useState(new AntennasModel().antennasData);
  const [antennasList, setAntennasList] = useState([]);

  useEffect(() => {
    loadAntennasData()
      .then(response => {
        const antennasModel = new AntennasModel(response);
        setAntennaGroups(antennasModel.antennasData.antennas.map(group => group.groupName));
        setAntennaGroupSelectedInMenu(antennasModel.antennasData?.antennas[0]?.groupName ?? "");
        setInputValues(antennasModel.antennasData);
        setAntennaSelected(antennasModel.antennasData);
      });
  }, [towerId, userData.token]);

  useEffect(() => {
    loadAntennaTypes().then(response => {
      if (!response || response?.length === 0) return setAntennasList([]);

      const groupedAntennas = response.reduce((acc, antenna) => {
        let type;
        if (antenna.tipo === "MW") {
          type = "MW CHEIA";
        } else if (antenna.tipo === "PV") {
          type = "PAINEL VAZADO";
        } else {
          type = antenna.tipo;
        }

        if (!acc[type]) acc[type] = [];
        acc[type].push(antenna);
        return acc;
      }, {});
      setAntennasList(groupedAntennas);
    });
  }, [towerId, userData.token]);

  const loadAntennasData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchAntennas(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das antenas.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchAntennas, towerId, userData.token]);

  const loadAntennaTypes = useCallback(async() => {
    setIsLoading(true);
    try {
      return await getAllAntennas(userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados dos tipos das antenas.");
    } finally {
      setIsLoading(false);
    }
  }, [getAllAntennas, towerId, userData.token]);

  const operatorOptions = ["OI", "TIM", "VIVO", "CLARO", "COMPARTILHADO"];
  const conditionOptions = ["Existente", "Retirar", "Instalar", "Reserva"];
  const antennasTypes = ["ANTENAS", "DIPLEXER", "GPS", "HELICOIDAL 1", "HELICOIDAL 2", "HELICOIDAL 4",
    "HELICOIDAL 7", "MW CHEIA", "MW VAZADA", "ODU", "OMNI", "PAINEL VAZADO", "QUADRIPLEXER", "RESERVA", "RF",
    "RRU", "TMA", "TRIPLEXER", "YAGI 1", "YAGI 2", "YAGI 4", "YAGI 7", "YAGI 8"];

  function getIsModified() {
    const isCableTrayLengthModified = inputValues.cableTrayLength !== antennaSelected.cableTrayLength;
    const isAntennasModified = JSON.stringify(antennaSelected) !== JSON.stringify(inputValues);

    return isAntennasModified || isCableTrayLengthModified || inputValues.antennas.length !== antennaSelected.antennas.length;
  }

  const isInputModified = getIsModified();

  const handleInputChange = useCallback((field, value, groupName) => {
    const antennasModel = new AntennasModel(inputValues);

    if (field === "cableTrayLength") {
      antennasModel.updateCableTrayLength(value);
      setInputValues(antennasModel.antennasData);
      return;
    }

    if (antennaGroupSelectedInMenu === "" || antennaGroupSelectedInMenu === undefined) return;

    const antennasName = groupName ? groupName : antennaGroupSelectedInMenu;
    const currentGroup = antennasModel.antennasData.antennas?.find(group => group.groupName === antennasName);

    const antennaInfo = antennasList[currentGroup?.type]?.find(antenna => antenna.modelo === value);
    antennasModel.updateAntenna(field, value, antennasName, antennaInfo, windSelected);

    const updatedAntennaGroups = antennasModel.antennasData.antennas.map(antenna => antenna?.groupName);
    setAntennaGroups(updatedAntennaGroups);
    setAntennaGroupSelectedInMenu(antennasModel.antennasData.antennas?.find(antenna =>
      Number(antenna?.group[0]) === Number(currentGroup?.group[0])).groupName);
    setInputValues(antennasModel.antennasData);
  }, [inputValues, setInputValues, setAntennaGroups, antennaGroupSelectedInMenu, setAntennaGroupSelectedInMenu]);

  function addAntenna() {
    const antennasModel = new AntennasModel(inputValues);
    antennasModel.addAntenna();

    const newAntennaGroup = antennasModel.antennasData.antennas[
      antennasModel.antennasData.antennas.length - 1];

    setAntennaGroups(prevState => [...prevState, newAntennaGroup.groupName]);
    setAntennaGroupSelectedInMenu(newAntennaGroup.groupName);
    setInputValues(antennasModel.antennasData);
  }

  function deleteAntenna() {
    if (!window.confirm(`Tem certeza de que deseja deletar a(s) antena(s) ${antennaGroupSelectedInMenu} ?`)) return;

    const antennasModel = new AntennasModel(inputValues);
    antennasModel.deleteAntenna(antennaGroupSelectedInMenu);

    const updatedAntennaGroups = antennasModel.antennasData.antennas.map(antenna => antenna?.groupName);
    setAntennaGroups(updatedAntennaGroups);
    setAntennaGroupSelectedInMenu(updatedAntennaGroups.length > 0 ? updatedAntennaGroups[0] : "");
    setInputValues(antennasModel.antennasData);
  }

  async function updateAntennasData() {
    const antennasModel = new AntennasModel(inputValues);
    if (!antennasModel.validate(geometrySelected.totalHeight)) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveAntennas(antennasModel.toJSON(), towerId, userData.token);
      const model = new AntennasModel(updatedData).antennasData;
      setAntennaSelected(model);
      setInputValues(model);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das antenas atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das antenas.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Antenas"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateAntennasData}
      >
        <TowerAntennaLeftMenu
          antennaGroups={antennaGroups}
          antennaGroupSelectedInMenu={antennaGroupSelectedInMenu}
          setAntennaGroupSelectedInMenu={setAntennaGroupSelectedInMenu}
          inputValues={inputValues}
          handleInputChange={(field, value, antenna) => handleInputChange(field, value, antenna)}
          addAntenna={addAntenna}
          deleteAntenna={deleteAntenna}
          operatorOptions={operatorOptions}
          conditionOptions={conditionOptions}
          antennasTypes={antennasTypes}
          antennasList={antennasList}
        />
      </LeftMenu>
      <RightContainer>
        <TowerAntennaRightContainer
          antennaGroupSelectedInMenu={antennaGroupSelectedInMenu}
          inputValues={inputValues}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
