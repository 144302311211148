import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerSectionLeftMenu from "./tower-sectionLeftMenu";
import TowerSectionRightContainer from "./tower-sectionRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerSections } from "../../../hooks/api/tower-api/useTowerSections";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import SectionsModel from "../../../models/SectionModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TowerSectionsPage() {
  const { userData } = useContext(UserContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);
  const { towerId, towerImages, setTowerImages, geometrySelected, trussesSelected, sectionsSelected, setSectionsSelected } = useContext(TowerContext);

  const { act: fetchSections } = useTowerSections("get");
  const { act: saveSections } = useTowerSections("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const geometryTotalHeight = Number(geometrySelected.totalHeight) || "";
  const geometryTopWidth = Number(geometrySelected.topWidth) || "";
  const geometryBaseWidth = Number(geometrySelected.baseWidth) || "";
  const trussesHeights = trussesSelected.map(truss => truss.totalHeight);

  const [sections, setSections] = useState(["Seção 1"]);
  const [sectionSelectedInMenu, setSectionSelectedInMenu] = useState(1);
  const [sectionHeights, setSectionHeights] = useState({ section1: 0, section2: 0, section3: 0 });
  const [inputValues, setInputValues] = useState(new SectionsModel().sections);

  useEffect(() => {
    loadSections().then(response => setInitialValues(response));
  }, [towerId, userData.token]);

  const loadSections = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchSections(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das seções.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchSections, towerId, userData.token]);

  function setInitialValues(sectionsData) {
    const initialData = new SectionsModel(
      [...Array(sectionsData.length === 0 ? 1 : sectionsData.length)]
        .map((_, i) => ({
          sectionNumber: i + 1,
          baseWidth: sectionsData[i]?.baseWidth ?? "",
          finalSegment: sectionsData[i]?.finalSegment ?? "",
          initialSegment: i === 0 ? 1 : sectionsData[i]?.initialSegment ?? "",
          topWidth: i === 0 ? geometryTopWidth : sectionsData[i]?.topWidth ?? "",
        }))
    );
    initialData.updateSection(1, "initialSegment", 1, nSegments, geometryTopWidth, geometryBaseWidth);
    setSectionHeights(initialData.updateSectionHeights(trussesHeights));
    const sections = initialData.sections?.map(obj => `Seção ${obj.sectionNumber}`);
    setSections(sections);
    setInputValues(initialData.sections);
  }

  const fieldsToCheck = ["initialSegment", "finalSegment", "topWidth", "baseWidth"];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], sectionsSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value, section) => {
    setSectionSelectedInMenu(prev => section ? section : prev);

    const sectionsModel = new SectionsModel(inputValues);
    sectionsModel.updateSection(Number(sectionSelectedInMenu), field, value, nSegments, geometryTopWidth, geometryBaseWidth);
    setSectionHeights(sectionsModel.updateSectionHeights(trussesHeights));
    setInputValues(sectionsModel.sections);
  }, [inputValues, sectionSelectedInMenu]);

  async function updateSectionsData() {
    const sectionsModel = new SectionsModel(inputValues);
    if (!sectionsModel.validate(geometryTotalHeight, geometryTopWidth, geometryBaseWidth, trussesHeights)) return false;

    setIsLoading(true);
    inputValues.map((section, index) => {
      if(!section.sectionNumber) {
        section.sectionNumber = index + 1;
      }
      return section;
    });

    try {
      const updatedData = await saveSections(JSON.stringify(inputValues), towerId, userData.token);
      setSectionsSelected(updatedData);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das seções atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das seções.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Seções"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateSectionsData}
      >
        <TowerSectionLeftMenu
          nSegments={nSegments}
          sections={sections}
          setSections={setSections}
          inputValues={inputValues}
          setInputValues={setInputValues}
          sectionSelectedInMenu={sectionSelectedInMenu}
          setSectionSelectedInMenu={setSectionSelectedInMenu}
          handleInputChange={(field, value, section) => handleInputChange(field, value, section)}
          setSectionHeights={setSectionHeights}
          trussesHeights={trussesHeights}
        />
      </LeftMenu>
      <RightContainer>
        <TowerSectionRightContainer
          inputValues={inputValues}
          sectionSelectedInMenu={sectionSelectedInMenu}
          handleInputChange={(field, value, section) => handleInputChange(field, value, section)}
          geometryTotalHeight={geometryTotalHeight}
          geometryTopWidth={geometryTopWidth}
          geometryBaseWidth={geometryBaseWidth}
          sectionHeights={sectionHeights}
          towerImages={towerImages}
          setTowerImages={setTowerImages}
          trussesSelected={trussesSelected}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
