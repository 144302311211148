import React from "react";
import styled from "styled-components";
import CustomTable from "../../common/customTable";
import TowerMountReforceContainer from "./tower_mountReforce";
import TowerSilhouette from "../../common/tower-silhouette";
import colors from "../../../constants/color-constants";

export default function TowerMountRightContainer({ 
  nSegments,
  inputValues,
  segmentSelectedInMenu,
  hasReinforcement,
  handleReinforcementChange,
  trussesSelected,
  towerImages,
}) {
  const tableColumnTitles = [
    "Trecho", "Montante Existente", "Parafusos", "Montante Reforço", "Parafusos"];

  const generateTableContent = () => {
    return Array.from({ length: nSegments }, (_, index) => [
      index + 1,
      formatProfileDimensions(index, false),
      inputValues[index]?.diameterConnectionBolts ?? "",
      formatProfileDimensions(index, true),
      inputValues[index]?.reinforcementData?.diameterConnectionBolts ?? "",
    ]);
  };

  function formatProfileDimensions(index, isReinforcement) {
    if (isReinforcement) {
      const selectedProfileType = inputValues[index]?.reinforcementData?.profileType;
      if (selectedProfileType === "L - Cantoneira") {
        return inputValues[index]?.reinforcementData?.profileDimensions ?? "";
      } else if (selectedProfileType === "T - Tubo Vazado") {
        return `${inputValues[index]?.reinforcementData?.profileDiameter ?? ""} ${
          inputValues[index]?.reinforcementData?.profileThickness ? 
            inputValues[index]?.reinforcementData?.profileThickness + "mm" : ""}`;
      } else if (selectedProfileType === "BR - Barra Maciça") {
        return inputValues[index]?.reinforcementData?.profileDiameter ?? "";
      } else if (selectedProfileType === "O - Ômega") {
        return `${inputValues[index]?.reinforcementData?.profileFlange ? 
          inputValues[index]?.reinforcementData?.profileFlange + "mm" : ""} ${
          inputValues[index]?.reinforcementData?.profileWeb ? 
            inputValues[index]?.reinforcementData?.profileWeb + "mm" : ""} ${
          inputValues[index]?.reinforcementData?.profileThickness ?? ""}`;
      }
    } else {
      const selectedProfileType = inputValues[index]?.profileType;
      if (selectedProfileType === "L - Cantoneira") {
        return inputValues[index]?.profileDimensions;
      } else if (selectedProfileType === "T - Tubo Vazado") {
        return `${inputValues[index]?.profileDiameter} ${
          inputValues[index]?.profileThickness ? inputValues[index]?.profileThickness + "mm" : ""}`;
      } else if (selectedProfileType === "BR - Barra Maciça") {
        return inputValues[index]?.profileDiameter;
      } else if (selectedProfileType === "O - Ômega") {
        return `${inputValues[index]?.profileFlange ? inputValues[index]?.profileFlange + "mm" : ""} ${
          inputValues[index]?.profileWeb ? inputValues[index]?.profileWeb + "mm" : ""} ${
          inputValues[index]?.profileThickness ?? ""}`;
      }
    }
  }

  return (
    <Container>
      {
        hasReinforcement ? 
          <TowerMountReforceContainer
            segmentSelectedInMenu={segmentSelectedInMenu}
            inputValues={inputValues}
            handleReinforcementChange={(field, value) => handleReinforcementChange(field, value)}
          /> : <></>
      }

      <ImageTableContainer>
        <ImgContainer>
          <h2>Montante</h2>
          {trussesSelected[segmentSelectedInMenu - 1]?.trussType &&
            <PositionalContainer>
              <MountDataLeftContainer left={"0px"}>
                <MountDataInputContainer>
                  <h2>Montante Existente</h2>
                  <div>
                    { formatProfileDimensions(segmentSelectedInMenu - 1, false) } <br />
                    { inputValues[segmentSelectedInMenu - 1]?.profileSteel ?? "" } <br />
                    { inputValues[segmentSelectedInMenu - 1]?.diameterConnectionBolts ?? "" }
                    {" "}
                    { inputValues[segmentSelectedInMenu - 1]?.boltsSteel?.slice(5) ?? "" }
                  </div>
                </MountDataInputContainer>
              </MountDataLeftContainer>

              <SectionImg>
                <img
                  src={towerImages[segmentSelectedInMenu - 1] ?? ""}
                  width={"220px"}
                  alt={""}
                />
              </SectionImg>

              <MountDataRightContainer right={"0px"}>
                {
                  inputValues[segmentSelectedInMenu - 1]?.hasReinforcement === "Sim" ?
                    <MountDataInputContainer>
                      <h2>Montante Reforço</h2>
                      <div>
                        {formatProfileDimensions(segmentSelectedInMenu - 1, true) } <br />
                        { inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.profileSteel ?? "" } <br />
                        { inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.diameterConnectionBolts ?? "" }
                        {" "}
                        { inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.boltsSteel?.slice(5) ?? "" }
                      </div>
                    </MountDataInputContainer>
                    : <></>
                }
              </MountDataRightContainer>
            </PositionalContainer>
          }
        </ImgContainer>

        <TableContainer>
          <CustomTable 
            lines={nSegments} columns={5} 
            columnTitles={tableColumnTitles} 
            content={generateTableContent()} 
          />
        </TableContainer>
      </ImageTableContainer>

      <TowerImgContainer>
        <h2>Silhueta</h2>
        <TowerSilhouette images={towerImages} />
      </TowerImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 30px;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`;

const ImageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100vh - 245px);
  gap: 30px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 18px 20px 8px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};
`;

const PositionalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    margin: 0 10px 0;
    object-fit: contain;
    height: 300px;
  }
`;

const MountDataLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: ${(props) => props.left};
`;

const MountDataRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: ${(props) => props.right};
`;

const MountDataInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 14px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 125px;
    height: min-content;
    min-height: 90px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid ${colors.kcBorderGray};
    background-color: ${colors.kcWhiteColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 38px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  height: min-content;
  border-radius: 6px;
  overflow-y: scroll;
`;

const TowerImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
`;
