import styled from "styled-components";
import CustomTable from "../../common/customTable";
import TowerSilhouette from "../../common/tower-silhouette";
import colors from "../../../constants/color-constants";

export default function TowerRedundantRightContainer({ 
  nRedundants,
  inputValues,
  segmentSelectedInMenu,
  trussesSelected,
  towerImages,
}) {
  const tableColumnTitles = ["Barra", "Redundante", "Parafuso"];
  
  const generateTableContent = () => {
    return Array.from({ length: nRedundants[segmentSelectedInMenu - 1] || 0 }, (_, index) => [
      `R${inputValues[segmentSelectedInMenu - 1]?.redundants[index]?.redundant  ?? ""}`,
      formatProfileDimensions(index),
      inputValues[segmentSelectedInMenu - 1]?.redundants[index]?.diameterConnectionBolts ?? "",
    ]);
  };

  function formatProfileDimensions(index) {
    const selectedRedundant = inputValues[segmentSelectedInMenu - 1]?.redundants[index];
    if (!selectedRedundant?.profileType) return;
    
    if (selectedRedundant.profileType === "L - Cantoneira") {
      return selectedRedundant?.profileDimensions ?? "";
    } else if (selectedRedundant.profileType === "T - Tubo Vazado") {
      return `${selectedRedundant?.profileDiameter ?? ""} 
        ${selectedRedundant?.profileThickness ? selectedRedundant?.profileThickness + "mm" : ""}`;
    } else if (selectedRedundant.profileType === "BR - Barra Maciça") {
      return selectedRedundant?.profileDiameter ?? "";
    } else if (selectedRedundant.profileType === "U - Perfil U") {
      return `${selectedRedundant?.profileFlange ? selectedRedundant?.profileFlange + "mm" : ""} 
        ${selectedRedundant?.profileWeb ? selectedRedundant?.profileWeb + "mm" : ""} 
        ${selectedRedundant?.profileThickness ?? ""}`;
    }
  }

  return (
    <Container>
      {inputValues[segmentSelectedInMenu - 1]?.redundants &&
        <ImageTableContainer>
          <ImgContainer>
            <h2>Redundantes</h2>
            {trussesSelected[segmentSelectedInMenu - 1]?.trussType &&
              <PositionalContainer>
                <SectionImg>
                  <img
                    src={towerImages[segmentSelectedInMenu - 1] ?? ""}
                    width={"220px"}
                    alt={""}
                  />
                </SectionImg>
              </PositionalContainer>
            }
          </ImgContainer>

          <TableContainer>
            <CustomTable
              lines={nRedundants[segmentSelectedInMenu - 1] || 0}
              columns={3}
              columnTitles={tableColumnTitles}
              content={generateTableContent()}
            />
          </TableContainer>
        </ImageTableContainer>
      }

      <TowerImgContainer>
        <h2>Silhueta</h2>
        <TowerSilhouette images={towerImages} />
      </TowerImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 30px;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`;

const ImageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100vh - 245px);
  gap: 30px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 18px 20px 8px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};
`;

const PositionalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    margin: 0 10px 0;
    object-fit: contain;
    height: 300px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  height: min-content;
  border-radius: 6px;
  overflow-y: scroll;
`;

const TowerImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
`;
