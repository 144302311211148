import styled from "styled-components";
import CustomTable from "../../common/customTable";
import TowerInferiorDiagonalContainer from "./tower_inferior-diagonal-container";
import colors from "../../../constants/color-constants";
import TowerSilhouette from "../../common/tower-silhouette";
import React from "react";

export default function TowerDiagonalRightContainer({
  nSegments,
  inputValues,
  segmentSelectedInMenu,
  handleInferiorDiagonalChange,
  trussesSelected,
  towerImages,
}) {
  const tableColumnTitles = [
    "Trecho", "Diagonal Superior", "Parafusos", "Diagonal Inferior", "Parafusos"];
  
  const generateTableContent = () => {
    return Array.from({ length: nSegments }, (_, index) => [
      index + 1,
      formatProfileDimensions(index, false),
      inputValues[index]?.diameterConnectionBolts ?? "",
      formatProfileDimensions(index, true),
      inputValues[index]?.inferiorDiagonalData?.diameterConnectionBolts ?? "",
    ]);
  };

  function formatProfileDimensions(index, isInferiorDiagonal) {
    if (isInferiorDiagonal) {
      const selectedProfileType = inputValues[index]?.inferiorDiagonalData?.profileType;
      if (selectedProfileType === "L - Cantoneira") {
        return inputValues[index].inferiorDiagonalData?.profileDimensions ?? "";
      } else if (selectedProfileType === "T - Tubo Vazado") {
        return `${inputValues[index].inferiorDiagonalData?.profileDiameter ?? ""} ${
          inputValues[index].inferiorDiagonalData?.profileThickness ? 
            inputValues[index].inferiorDiagonalData?.profileThickness + "mm" : ""}`;
      } else if (selectedProfileType === "BR - Barra Maciça") {
        return inputValues[index].inferiorDiagonalData?.profileDiameter ?? "";
      } else if (selectedProfileType === "U - Perfil U") {
        return `${inputValues[index].inferiorDiagonalData?.profileFlange ? 
          inputValues[index].inferiorDiagonalData?.profileFlange + "mm" : ""} ${
          inputValues[index].inferiorDiagonalData?.profileWeb ? 
            inputValues[index].inferiorDiagonalData?.profileWeb + "mm" : ""} ${
          inputValues[index].inferiorDiagonalData?.profileThickness ?? ""}`;
      }
    } else {
      const selectedProfileType = inputValues[index]?.profileType;
      if (selectedProfileType === "L - Cantoneira") {
        return inputValues[index].profileDimensions;
      } else if (selectedProfileType === "T - Tubo Vazado") {
        return `${inputValues[index].profileDiameter} ${
          inputValues[index].profileThickness ? inputValues[index].profileThickness + "mm" : ""}`;
      } else if (selectedProfileType === "BR - Barra Maciça") {
        return inputValues[index].profileDiameter;
      } else if (selectedProfileType === "U - Perfil U") {
        return `${inputValues[index]?.profileFlange ? inputValues[index]?.profileFlange + "mm" : ""} ${
          inputValues[index]?.profileWeb ? inputValues[index]?.profileWeb + "mm" : ""} ${
          inputValues[index].profileThickness ?? ""}`;
      }
    }
  }

  return (
    <Container>
      {
        inputValues[segmentSelectedInMenu - 1]?.isInferiorDiagonalDifferent === "Sim" &&
          <TowerInferiorDiagonalContainer
            segmentSelectedInMenu={segmentSelectedInMenu}
            inputValues={inputValues}
            handleInferiorDiagonalChange={(field, value) => handleInferiorDiagonalChange(field, value)}
          />
      }

      <ImageTableContainer>
        <ImgContainer>
          <h2>Diagonais</h2>
          {trussesSelected[segmentSelectedInMenu - 1]?.trussType &&
            <PositionalContainer>
              <DiagonalDataLeftContainer left={"0px"}>
                {
                  inputValues[segmentSelectedInMenu - 1]?.isInferiorDiagonalDifferent === "Sim" &&
                  <DiagonalDataInputContainer>
                    <h2>Diagonal Inferior</h2>
                    <div>
                      { formatProfileDimensions(segmentSelectedInMenu - 1, true) } <br />
                      { inputValues[segmentSelectedInMenu - 1].inferiorDiagonalData?.profileSteel ?? "" } <br />
                      { inputValues[segmentSelectedInMenu - 1].inferiorDiagonalData?.diameterConnectionBolts ?? "" }
                      {" "}
                      { inputValues[segmentSelectedInMenu - 1].inferiorDiagonalData?.boltsSteel?.slice(5) ?? "" }
                    </div>
                  </DiagonalDataInputContainer>
                }
              </DiagonalDataLeftContainer>

              <SectionImg>
                <img
                  src={towerImages[segmentSelectedInMenu - 1] ?? ""}
                  width={"220px"}
                  alt={""}
                />
              </SectionImg>

              <DiagonalDataRightContainer right={"0px"}>
                <DiagonalDataInputContainer>
                  <h2>Diagonal Superior</h2>
                  <div>
                    {formatProfileDimensions(segmentSelectedInMenu - 1, false)} <br/>
                    {inputValues[segmentSelectedInMenu - 1]?.profileSteel ?? ""} <br/>
                    { inputValues[segmentSelectedInMenu - 1]?.diameterConnectionBolts ?? "" }
                    {" "}
                    { inputValues[segmentSelectedInMenu - 1]?.boltsSteel?.slice(5) ?? "" }
                  </div>
                </DiagonalDataInputContainer>
              </DiagonalDataRightContainer>
            </PositionalContainer>
          }
        </ImgContainer>

        <TableContainer>
          <CustomTable 
            lines={nSegments}
            columns={5}
            columnTitles={tableColumnTitles} 
            content={generateTableContent()} 
          />
        </TableContainer>
      </ImageTableContainer>

      <TowerImgContainer>
        <h2>Silhueta</h2>
        <TowerSilhouette images={towerImages} />
      </TowerImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 30px;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`;

const ImageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100vh - 245px);
  gap: 30px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 585px;
  padding: 18px 20px 8px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};
`;

const PositionalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    margin: 0 10px 0;
    object-fit: contain;
    height: 300px;
  }
`;

const DiagonalDataLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: ${(props) => props.left};
`;

const DiagonalDataRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: ${(props) => props.right};
`;

const DiagonalDataInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 14px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 125px;
    height: min-content;
    min-height: 90px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid ${colors.kcBorderGray};
    background-color: ${colors.kcWhiteColor};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 38px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 585px;
  height: min-content;
  border-radius: 6px;
  overflow-y: scroll;
`;

const TowerImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
`;
