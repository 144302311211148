import React from "react";
import styled from "styled-components";
import colors from "../../constants/color-constants";

export default function ChangeSegmentButtonsContainer({
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  nSegments,
  previousButtonText,
  nextButtonText
}) {
  function handlePreviousSegment() {
    if (segmentSelectedInMenu === "") return;
    if (Number(segmentSelectedInMenu) === 1) {
      setSegmentSelectedInMenu("");
      return;
    }
    setSegmentSelectedInMenu(Number(segmentSelectedInMenu) - 1);
  }

  function handleNextSegment() {
    if (segmentSelectedInMenu === "") {
      setSegmentSelectedInMenu(1);
      return;
    }
    if (Number(segmentSelectedInMenu) === Number(nSegments)) return;
    setSegmentSelectedInMenu(Number(segmentSelectedInMenu) + 1);
  }

  return (
    <Container>
      <button onClick={handlePreviousSegment}>
        <ion-icon name="chevron-back-outline"></ion-icon>
        {previousButtonText}
      </button>

      <button onClick={handleNextSegment}>
        {nextButtonText}
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 8px 0;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 8px;
    gap: 4px;
    border-radius: 6px;
    background-color: ${colors.kcButtonLightGray};
    color: ${colors.kcBlackColor};
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;

    :hover {
      background-color: ${colors.kcButtonGray};
    }

    >ion-icon {
      font-size: 14px;
    }
  }
`;
