import styled from "styled-components";

export const InputMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
  position: relative;
  font-family: 'Roboto', sans-serif;

  .input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .unit {
    margin-left: 5px;
  }

  h2 {
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }

  ion-icon {
    width: 16px;
    font-size: 16px;
    transform: ${(props) => (props.isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
    transition: transform 0.3s ease;
    color: #969696;
  }

  input, select {
    width: 100%;
    border: 1px solid #C8C8C8;
    height: 40px;
    padding: 6px 6px 6px 12px;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #313131;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
    
  select { cursor: pointer }

  select.highlightColor {
    background-color: #FE8F03;
    color:#FFFFFF;
  }

  select:disabled, input:disabled {
    background-color: #FFFFFF;
    color: #8C8C8C;
  }

  input::placeholder, select::placeholder {
    color: #313131;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }

  >div>div{
    width: 100%;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px 10px 4px 16px;
    border: 1px solid #C8C8C8;
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  
  li {
    width: 100%;
    flex-wrap: wrap;
    padding: 2px 0;
    border-top: 1px solid #F2F2F2;
    color: #313131;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`;
